import { backendApi } from "../api"

const fallDetectionHistoryApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    listFallDetectionHistoryForSensor: build.query<
      ListResponse,
      ListFallDetectionHistoryForSensorRequest
    >({
      query: (request) => ({
        url: `sensor/falldetection/history/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["FallDetection"],
    }),
    listFallDetectionNotificationsForCustomer: build.query<
      ListResponse,
      ListFallDetectionNotificationsForCustomerRequest
    >({
      query: (request) => ({
        url: `sensor/falldetection/history/notifications/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["FallDetection"],
    }),
    getFallCountForCluster: build.query<
      number,
      GetFallCounterForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/falldetection/history/notifications/count`,
        method: "GET",
        params: request,
      }),
      providesTags: ["FallDetection"],
    }),
  }),
})

export const {
  useListFallDetectionHistoryForSensorQuery,
  useListFallDetectionNotificationsForCustomerQuery,
  useGetFallCountForClusterQuery,
} = fallDetectionHistoryApi

export interface FallDetectionHistoryEntry {
  id: string
  sensorId: string
  clusterId: string
  orgId: string
  serialNumber: string
  gaddIndex: number
  messageTimestamp: string
}

export interface ListResponse {
  total: number
  limit: number
  page: number
  data: FallDetectionHistoryEntry[]
}

export interface ListFallDetectionHistoryForSensorRequest {
  sensorId: string | undefined
  limit?: number
  page?: number
}

export interface ListFallDetectionNotificationsForCustomerRequest {
  orgId: string | undefined
  limit?: number
  page?: number
}

export interface GetFallCounterForClusterRequest {
  orgId: string | undefined
  clusterId: string | undefined
}
