import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { v4 as uuidv4 } from "uuid"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { usePutIdentityLinkMutation } from "@/services/auth/identity-link"
import { usePutAssignmentMutation } from "@/services/auth/org-assignment"

const formSchema = z.object({
  id: z.string(),
  userId: z.string(),
  orgId: z.string(),
  role: z.string(),
})

function CreateObjectAssignmentForm(): ReactElement {
  const [open, setOpen] = useState(false)
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: "",
      userId: "",
      orgId: "",
      role: "",
    },
  })

  const [createAssignment, { isSuccess }] = usePutAssignmentMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    createAssignment({
      orgAssignment: {
        id: uuidv4(),
        userId: values.userId,
        orgId: values.orgId,
        role: values.role,
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          Neuen Organisation Assignment erstellen
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Neuen Organisation Assignment erstellen</DialogTitle>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="userId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>User Id</FormLabel>
                    <FormControl>
                      <Input placeholder="User Id" {...field} />
                    </FormControl>
                    <FormDescription>User Id</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="orgId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organisation Id</FormLabel>
                    <FormControl>
                      <Input placeholder="Organisation Id" {...field} />
                    </FormControl>
                    <FormDescription>Organisation Id</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>User Id</FormLabel>
                    <FormControl>
                      <Input placeholder="Rolle" {...field} />
                    </FormControl>
                    <FormDescription>Rolle</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateObjectAssignmentForm
