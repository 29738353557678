import { backendApi } from "../api"

const identityLinkApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putIdentityLink: build.mutation<IdentityLink, PutIdentityLinkRequest>({
      query: (request) => ({
        url: `auth/identitylink`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["IdentityLink"],
    }),
    listIdentityLinks: build.query<List, ListCustomersRequest>({
      query: (request) => ({
        url: `auth/identitylink/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["IdentityLink"],
    }),
    getIdentityLinksForUser: build.query<
      IdentityLink,
      GetIdentityLinksForUserRequest
    >({
      query: (request) => ({
        url: `auth/identitylink`,
        method: "GET",
        params: request,
      }),
      providesTags: ["IdentityLink"],
    }),
  }),
})

export const {
  usePutIdentityLinkMutation,
  useListIdentityLinksQuery,
  useGetIdentityLinksForUserQuery,
} = identityLinkApi

export interface IdentityLink {
  userId: string
  identityProviderId: string
  identityLinkType: string
}

export interface List {
  identityLinks: IdentityLink[]
}

export interface PutIdentityLinkRequest {
  identityLink: IdentityLink
}

export interface ListCustomersRequest {}

export interface GetIdentityLinksForUserRequest {
  userId: string
}
