import React, { ReactElement } from "react"
import { Badge } from "@/components/ui/badge"
import { getDateString } from "@/lib/utils"

interface DistanceOverviewRowProps {
  description: string
  distance: number
  lastSignal?: string
  threshold: number
}

function isValid(distance: number, threshold: number): boolean {
  if (distance > threshold) {
    return true
  }

  return false
}

function DistanceOverviewRow({
  description,
  distance,
  threshold,
  lastSignal,
}: DistanceOverviewRowProps): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-16 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{description}</h2>
        <p className="text-sm text-muted-foreground">
          Letzte Nachricht: {getDateString(lastSignal!)}
        </p>
      </div>
      <Badge
        variant={isValid(distance, threshold) ? "default" : "destructive"}
        className="h-8"
      >
        {distance} mm
      </Badge>
    </div>
  )
}

export default DistanceOverviewRow
