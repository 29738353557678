import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import createColumns from "./columns"
import { useListAssignmentsQuery } from "@/services/auth/org-assignment"

function OrgAssignmentTable(): ReactElement {
  const { data } = useListAssignmentsQuery({})

  return data != null && data.orgAssignments != null ? (
    <div className="mx-auto py-4">
      <DataTable
        enableToolbar={false}
        columns={createColumns()}
        data={data.orgAssignments}
      />
    </div>
  ) : (
    <div />
  )
}

export default OrgAssignmentTable
