import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import createColumns from "./columns"
import { useListUsersQuery } from "@/services/users/users"

function UsersTable(): ReactElement {
  const { data } = useListUsersQuery({})

  return data != null && data.users != null ? (
    <div className="mx-auto py-4">
      <DataTable
        enableToolbar={false}
        columns={createColumns()}
        data={data.users}
      />
    </div>
  ) : (
    <div />
  )
}

export default UsersTable
