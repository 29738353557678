import React, { ReactElement } from "react"
import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"
import { useListTemperatureHistoryForSensorQuery } from "@/services/sensors/temperature-history"
import { skipToken } from "@reduxjs/toolkit/query"

interface TemperatureChartProps {
  sensorId: string
}

function TemperatureChart({ sensorId }: TemperatureChartProps): ReactElement {
  const { data: historyData } = useListTemperatureHistoryForSensorQuery(
    sensorId != null ? { sensorId } : skipToken,
  )

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={historyData?.data}>
        <XAxis
          dataKey="messageTimestamp"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => `${value}`}
        />
        <ReferenceLine y={10} label="Min" stroke="red" strokeDasharray="3 3" />
        <ReferenceLine y={17} label="Max" stroke="red" strokeDasharray="3 3" />
        <Line dataKey="temperature" fill="#adfa1d" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default TemperatureChart
