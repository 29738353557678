import React, { ReactElement } from "react"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel"
import FallSensorDashboardCard from "@/features/sensors/fallsensor/components/fall-sensor-dashboard-card"
import { useListFallDetectionSensorsForClusterQuery } from "@/services/sensors/fall-detection-sensor"
interface FallSensorDashboardComponentProps {
  clusterId?: string
  orgId?: string
}

function FallSensorDashboardComponent({
  clusterId,
  orgId,
}: FallSensorDashboardComponentProps): ReactElement {
  const { data: clusterData } = useListFallDetectionSensorsForClusterQuery(
    clusterId != null && clusterId !== "" ? { clusterId } : skipToken,
  )

  if (
    clusterData == null ||
    clusterData?.fallDetectionSensors == null ||
    clusterData?.fallDetectionSensors.length === 0
  ) {
    return <div />
  }

  return (
    <div className="w-full flex-col">
      <h1 className="text-2xl font-bold mb-2">Sturzsensoren</h1>
      <Carousel className="w-full">
        <CarouselContent className="">
          {clusterData.fallDetectionSensors.length > 0 && (
            <CarouselItem className="basis-1/3">
              <FallSensorDashboardCard orgId={orgId} clusterId={clusterId} />
            </CarouselItem>
          )}
        </CarouselContent>
      </Carousel>
    </div>
  )
}

export default FallSensorDashboardComponent
