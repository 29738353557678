import React, { ReactElement, useEffect, useRef } from "react"
import L, { icon } from "leaflet"
import "leaflet/dist/leaflet.css"
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { useListGpsHistoryForSensorQuery } from "@/services/sensors/gps-history"
import { skipToken } from "@reduxjs/toolkit/query"

export interface MarkerState {
  marker: L.Marker
  moveHandler?: (lat: number, lng: number) => void
}

interface MapProps {
  sensorId: string
}

export const marker = L.icon({
  iconUrl: markerIconPng,
  iconAnchor: [19, 40],
})

function Map({ sensorId }: MapProps): ReactElement {
  const mapRef = useRef<L.Map>()
  const markerGroupRef = useRef<L.LayerGroup>()

  const { data: gpsData } = useListGpsHistoryForSensorQuery(
    sensorId != null ? { sensorId } : skipToken,
  )

  useEffect(() => {
    if (mapRef.current == null) {
      mapRef.current = L.map("map").setView([100.3871744, 10.8612096], 13)
      if (markerGroupRef.current == null) {
        markerGroupRef.current = L.layerGroup()
      }

      if (markerGroupRef.current != null) {
        markerGroupRef.current.clearLayers()
      }

      if (gpsData != null && gpsData.data != null && gpsData.data.length > 0) {
        mapRef.current.setView(
          [gpsData.data[0].latitude, gpsData.data[0].longitude],
          50,
        )
      }
    }

    if (mapRef != null && mapRef.current != null) {
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(mapRef.current)
    }

    if (markerGroupRef.current != null) {
      markerGroupRef.current.addTo(mapRef.current as L.Map)
    }
  }, [mapRef, sensorId, gpsData])

  useEffect(() => {
    if (
      mapRef.current &&
      gpsData != null &&
      gpsData.data != null &&
      gpsData.data.length > 0
    ) {
      mapRef.current.setView(
        [gpsData.data[0].latitude, gpsData.data[0].longitude],
        13,
      )

      if (markerGroupRef.current != null) {
        markerGroupRef.current.clearLayers()
      }

      if (gpsData.data.length > 0) {
        L.marker([gpsData.data[0].latitude, gpsData.data[0].longitude], {
          icon: marker,
        }).addTo(markerGroupRef.current as L.LayerGroup)
      }
    }
  }, [gpsData])

  return <div id="map" className="overflow-hidden w-full h-96 z-0 rounded-lg" />
}

export default Map
