import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import React, { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { useMsal } from "@azure/msal-react"
import { useGetUserInfoQuery, User } from "@/services/users/users"
import { skipToken } from "@reduxjs/toolkit/query"

const getInitials = (fullName: string): string => {
  const allNames = fullName.trim().split(" ")
  const initials = allNames.reduce((acc, curr, index) => {
    let accVal = acc
    if (index === 0 || index === allNames.length - 1) {
      accVal = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return accVal
  }, "")
  return initials
}

const getRole = (user: User): string => {
  if (user.isGlobalAdmin) return "Globaler Admin"

  return ""
}

export function UserNav(): ReactElement {
  const nav = useNavigate()
  const { instance, accounts } = useMsal()
  const { data, isSuccess, isFetching, isLoading } = useGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { identityProviderId: accounts[0].localAccountId }
      : skipToken,
  )

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error))
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          {data != null && (
            <Avatar className="h-9 w-9">
              <AvatarFallback>{getInitials(data.displayName)}</AvatarFallback>
            </Avatar>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        {data != null && (
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {data.displayName}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {data.mail}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {getRole(data)}
              </p>
            </div>
          </DropdownMenuLabel>
        )}
        <DropdownMenuSeparator />
        {data != null && data.isGlobalAdmin && (
          <div>
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={() => nav("organisations/overview")}>
                Admineinstellungen
              </DropdownMenuItem>
            </DropdownMenuGroup>

            <DropdownMenuSeparator />
          </div>
        )}
        <DropdownMenuItem onClick={() => handleLogoutRedirect()}>
          Log out
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="text-xs leading-none  focus:bg-primary focus:text-white"
          onClick={() =>
            toast("Event has been created", {
              description: "Sunday, December 03, 2023 at 9:00 AM",
              action: {
                label: "Undo",
                onClick: () => console.log("Undo"),
              },
            })
          }
        >
          Open Source Lizenzen
        </DropdownMenuItem>
        <DropdownMenuLabel className="font-normal">
          <p className="text-xs leading-none text-muted-foreground">
            {`Version: ${import.meta.env.VITE_APP_VERSION}`}
          </p>
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
