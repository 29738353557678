import React, { ReactElement } from "react"
import CreateUserForm from "@/features/users/forms/create-user-form"
import UsersTable from "@/features/users/tables/users-table/users-table"

function UsersPage(): ReactElement {
  return (
    <div className="flex-col w-full">
      <CreateUserForm />
      <div className="mt-4 border-b" />
      <UsersTable />
    </div>
  )
}
export default UsersPage
