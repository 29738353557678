import { Separator } from "@/components/ui/separator"
import React, { ReactElement } from "react"
import { Outlet } from "react-router-dom"
import { SidebarNav } from "../components/sidebar-nav"

const sidebarNavItems = [
  {
    title: "Nutzer",
    href: "users",
  },
  {
    title: "Organisationen",
    href: "overview",
  },
  {
    title: "Identity Link",
    href: "identitylink",
  },
  {
    title: "Organisation Assignment",
    href: "orgassignment",
  },
]

export default function SettingsLayout(): ReactElement {
  return (
    <div className="hidden space-y-6  md:block w-full">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
        <p className="text-muted-foreground">
          Manage your account settings and set e-mail preferences.
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside>
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1 ">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
