import React, { ReactElement } from "react"

function GatewaysPage(): ReactElement {
  return (
    <div className="flex-col w-full">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">Gateways</h2>
      </div>
    </div>
  )
}

export default GatewaysPage
