import React, { ReactElement } from "react"
import { Badge } from "@/components/ui/badge"
import { getDateString } from "@/lib/utils"

interface OpeningOverviewRowProps {
  description: string
  switchState: boolean
  lastSignal?: string
}

function SwitchOverviewRow({
  description,
  switchState,
  lastSignal,
}: OpeningOverviewRowProps): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-16 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{description}</h2>
        <p className="text-sm text-muted-foreground">
          Letzte Nachricht: {getDateString(lastSignal!)}
        </p>
      </div>
      <Badge variant={!switchState ? "default" : "destructive"} className="h-8">
        {switchState ? "An" : "Aus"}
      </Badge>
    </div>
  )
}

export default SwitchOverviewRow
