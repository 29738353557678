import React, { ReactElement } from "react"

interface DateLabelProps {
  timestamp?: string
  className?: string
}

function DateTableLabel({
  timestamp,
  className,
}: DateLabelProps): ReactElement {
  if (timestamp === null || timestamp === "" || timestamp === undefined) {
    return <div className={className}>No date</div>
  }

  const parsedTimestamp = Date.parse(timestamp.replace("CEST", ""))
  if (!Number.isNaN(parsedTimestamp)) {
    const date = new Date(parsedTimestamp)
    return (
      <div className={`flex flex-col ${className}`}>
        <div className="font-semibold">{date.toLocaleDateString("de-DE")}</div>
        <div className="text-sm">{date.toLocaleTimeString("de-DE")}</div>
      </div>
    )
  }
  return <div className={className}>Invalid Date</div>
}

export default DateTableLabel
