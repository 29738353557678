/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { usePutCarbonDioxideSensorMutation } from "@/services/sensors/carbon-dioxide-sensor"
import { useParams } from "react-router-dom"

const formSchema = z.object({
  clusterId: z.string(),
  description: z.string(),
  orgId: z.string(),
  serialNumber: z.string(),
  sensorIdentifier: z.string(),
  modelNumber: z.string(),
  minLimit: z.string(),
  maxLimit: z.string(),
})

interface CreateCarbonDioxideFormProps {
  clusterId: string
}

function CreateCarbonDioxideForm({
  clusterId,
}: CreateCarbonDioxideFormProps): ReactElement {
  const [open, setOpen] = useState(false)
  const { orgId } = useParams()
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      orgId: orgId ? orgId : "",
      clusterId,
      modelNumber: "elsys:ers-co2",
      serialNumber: "",
      sensorIdentifier: "",
      minLimit: "",
      maxLimit: "",
    },
  })

  const [createCarbonDioxideSensor, { isSuccess, error, isError }] =
    usePutCarbonDioxideSensorMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    createCarbonDioxideSensor({
      carbonDioxideSensor: {
        id: uuidv4(),
        description: values.description,
        clusterId: values.clusterId,
        orgId: values.orgId,
        modelNumber: values.modelNumber,
        serialNumber: values.serialNumber,
        sensorIdentifier: values.sensorIdentifier,
        minLimit: Number(values.minLimit),
        maxLimit: Number(values.maxLimit),
        carbonDioxide: 0,
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Kohlenstoffdioxid anlegen</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Neuen Kohlenstoffdioxidsensor anlegen</DialogTitle>
          <DialogDescription>
            Legen Sie einen neuen Kohlenstoffdioxidsensor für dieses Cluster an!
          </DialogDescription>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Beschreibung" {...field} />
                    </FormControl>
                    <FormDescription>
                      Der Name für das SensorCluster
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="serialNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Seriennummer" {...field} />
                    </FormControl>
                    <FormDescription>
                      Die Seriennummer des Sensors
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="sensorIdentifier"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sensor Identifier</FormLabel>
                    <FormControl>
                      <Input placeholder="Sensor" {...field} />
                    </FormControl>
                    <FormDescription>
                      Sensor Identifier des Sensors
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="minLimit"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mindestwert</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step=".1"
                        placeholder="Mindestwert"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Mindestwert für diesen Sensor
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="maxLimit"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Maximalwert</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step=".1"
                        placeholder="Maximalwert"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Maximalwert für diesen Sensor
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateCarbonDioxideForm
