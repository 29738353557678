import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { provideBackendToken } from "@/shared/authorization/provide-backend-token"

export const backendApi = createApi({
  reducerPath: "backendApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    prepareHeaders: async (headers) => {
      const accessToken: string = await provideBackendToken()
      headers.set("Authorization", `Bearer ${accessToken}`)
      return headers
    },
  }),
  tagTypes: [
    "SensorCluster",
    "Temperature",
    "TemperatureHistory",
    "Humidity",
    "HumidityHistory",
    "Templates",
    "Organisations",
    "CarbonDioxide",
    "CarbonDioxideHistory",
    "Opening",
    "OpeningHistory",
    "Tasks",
    "Processes",
    "GPS",
    "Switch",
    "SwitchHistory",
    "Distance",
    "DistanceHistory",
    "Flows",
    "Workflows",
    "Users",
    "IdentityLink",
    "OrgAssignments",
    "FallDetection",
  ],
  endpoints: (build) => ({}),
})

// TODO move validation error in own file
export function fieldErrorsFor(field: string, error: unknown): string[] {
  if (error == null || !isValidationError(error)) {
    return []
  }

  return error?.data?.errors?.[field] ?? []
}

export function removeErrorsFromValidationError(
  field: string,
  error: unknown,
): ValidationError | undefined {
  if (error == null) {
    return undefined
  }
  if (!isValidationError(error)) {
    return undefined
  }

  // remove field from errors
  const newErrors = Object.keys(error.data.errors)
    .filter((key) => key !== field)
    .reduce((obj, key) => {
      const newObj = { ...obj }
      newObj[key] = error.data.errors[key]
      return newObj
    }, {} as FieldErrors)

  return {
    status: error.status,
    data: {
      msg: error.data.msg,
      errors: newErrors,
    },
  }
}

export interface ValidationError {
  status: number
  data: {
    msg: string
    errors: FieldErrors
  }
}

type FieldErrors = { [field: string]: string[] }

export function isValidationError(error: unknown): error is ValidationError {
  // null or not an object
  if (error == null || typeof error !== "object") {
    return false
  }
  // status field
  if (!("status" in error) || error.status !== 422) {
    return false
  }
  // data field
  if (!("data" in error) || typeof error.data !== "object") {
    return false
  }
  // msg field
  if (
    error.data == null ||
    !("msg" in error.data) ||
    typeof error.data.msg !== "string"
  ) {
    return false
  }
  // errors field
  return "errors" in error.data && isFieldErrors(error.data.errors)
}

function isFieldErrors(error: unknown): error is FieldErrors {
  if (typeof error !== "object" || error === null) {
    return false
  }
  // every object entry is a string array
  return Object.entries(error).every(
    ([, v]) => Array.isArray(v) && v.every((e) => typeof e === "string"),
  )
}

export const NullUUID = "00000000-0000-0000-0000-000000000000"
