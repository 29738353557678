import { backendApi } from "../api"

const templatesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    createDeviceFromTemplate: build.mutation<
      Template,
      CreateDeviceFromTemplateRequest
    >({
      query: (request) => ({
        url: `templates/create`,
        method: "Put",
        body: request,
      }),
      invalidatesTags: ["Templates"],
    }),
    listTemplates: build.query<TemplatesList, ListTemplatesRequest>({
      query: (request) => ({
        url: `templates`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Templates"],
    }),
  }),
})

export const { useListTemplatesQuery, useCreateDeviceFromTemplateMutation } =
  templatesApi

export interface Template {
  id: string
  description: string
  imageLink: string
  manufacturer: string
  modelNumber: string
  sensors: TemplateSensor[]
}

export interface TemplateSensor {
  id: string
  templateId: string
  sensorType: string
  description: string
  sensorIdentifier: string
}

export interface TemplatesList {
  templates: Template[]
}

export interface ListTemplatesRequest {}

export interface CreateDeviceFromTemplateRequest {
  templateId: string
  serialNumber: string
  description: string
  protocol: string
}
