import React, { ReactElement, useCallback } from "react"
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "reactflow"
import { v4 as uuidv4 } from "uuid"
import "reactflow/dist/style.css"
import { Button } from "@/components/ui/button"
import { ChevronLeft } from "lucide-react"
import ActionSheet from "@/features/flows/components/action-sheet"
import {
  MailCardNode,
  TemperatureCardNode,
} from "@/features/flows/components/action-card-node"
import { useNavigate, useParams } from "react-router-dom"

export interface ActionCardProps {
  id: "2"
  position: { x: 0; y: 100 }
  data: { label: "2" }
  type: "card"
}

const initialNodes = [
  { id: "1", position: { x: 0, y: 200 }, data: { label: "1" }, type: "card" },
  { id: "2", position: { x: 0, y: 100 }, data: { label: "2" }, type: "card" },
]

const nodeTypes = { temperature: TemperatureCardNode, mail: MailCardNode }

function ActionPage(): ReactElement {
  const { orgId } = useParams()
  const nav = useNavigate()
  const [nodes, setNodes] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  )

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  )

  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  )

  return (
    <div className="flex-col flex-1 pb-16 w-full">
      <div className="flex items-center justify-between space-y-2 mb-4 ">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row">
            <Button
              onClick={() => {
                nav(`/${orgId}/flows`)
              }}
              className="rounded-lg p-2 mr-2"
              variant="ghost"
            >
              <ChevronLeft />
            </Button>
            <h2 className="text-3xl font-bold tracking-tight">Actions</h2>
          </div>
          <ActionSheet
            addActionHandler={(type: string) => {
              const uuid = uuidv4()
              setNodes([
                ...nodes,
                {
                  id: uuid,
                  position: { x: 0, y: 200 },
                  data: { label: uuid },
                  type: type,
                },
              ])
            }}
          />
        </div>
      </div>
      <div className="h-full w-full">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </div>
  )
}

export default ActionPage
