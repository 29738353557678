import React, { ReactElement } from "react"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"
import { Button } from "@/components/ui/button"
import { PlusIcon, Thermometer } from "lucide-react"
import { Separator } from "@/components/ui/separator"
import ActionSheetRowItem from "@/features/flows/components/action-sheet-row-item"

interface ActionSheetProps {
  addActionHandler: (type: string) => void
}

function ActionSheet({ addActionHandler }: ActionSheetProps): ReactElement {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="rounded-lg" variant="secondary">
          <PlusIcon />
        </Button>
      </SheetTrigger>
      <SheetContent className={"space-y-4"}>
        <SheetHeader>
          <SheetTitle>Neue Action hinzufügen</SheetTitle>
          <SheetDescription>
            Actions sind Aktivitäten die bei gewissen Aktivitäten der Sensoren
            durchgeführt werden sollen.
          </SheetDescription>
        </SheetHeader>
        <Separator />
        <SheetHeader>
          <SheetTitle>Input Events</SheetTitle>
        </SheetHeader>
        <ActionSheetRowItem
          icon={<Thermometer className={"h-12 w-12"} />}
          title={"Temperaturverstoß"}
          content={<div></div>}
          description={
            "Sobald die Temperatur Grenzwerte verletzt trigger diese Action"
          }
          onClick={() => addActionHandler("temperature")}
        />
        <Separator />
        <SheetHeader>
          <SheetTitle>Output Events</SheetTitle>
        </SheetHeader>
        <ActionSheetRowItem
          icon={<Thermometer className={"h-12 w-12"} />}
          content={<div></div>}
          title={"Mail verschicken"}
          description={"Diese Aktion schickt eine Benachrichtigungsmail raus"}
          onClick={() => addActionHandler("mail")}
        />
      </SheetContent>
    </Sheet>
  )
}

export default ActionSheet
