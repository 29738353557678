import { ColumnDef } from "@tanstack/react-table"
import { User } from "@/services/users/users"

export default function createColumns(): ColumnDef<User>[] {
  return [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "displayName",
      header: "Anzeigenamen",
    },
    {
      accessorKey: "firstName",
      header: "Vorname",
    },
    {
      accessorKey: "lastName",
      header: "Nachname",
    },
    {
      accessorKey: "mail",
      header: "Mail",
    },
  ]
}
