import { ColumnDef } from "@tanstack/react-table"
import DateTableLabel from "@/components/date-table-label"
import { FallDetectionHistoryEntry } from "@/services/sensors/fall-detection-history"
import { SensorCluster } from "@/services/sensor-cluster/sensor-cluster"
import { FallDetectionSensor } from "@/services/sensors/fall-detection-sensor"

export default function createColumns(
  clusters: SensorCluster[] = [],
  sensors: FallDetectionSensor[] = [],
): ColumnDef<FallDetectionHistoryEntry>[] {
  const getSensorName = (sensorId: string): string => {
    const sensor = sensors.find((sensor) => sensor.id === sensorId)
    if (sensor === undefined) {
      return sensorId
    }

    return sensor.description
  }

  const getClusterName = (clusterId: string): string => {
    const cluster = clusters.find((cluster) => cluster.id === clusterId)
    if (cluster === undefined) {
      return clusterId
    }

    return cluster.description
  }

  return [
    {
      accessorKey: "sensorId",
      header: "Beschreibung",
      cell: (info) => getSensorName(info.getValue<string>()),
    },
    {
      accessorKey: "clusterId",
      header: "Sensorcluster",
      cell: (info) => getClusterName(info.getValue<string>()),
    },
    {
      accessorKey: "gaddIndex",
      header: "Gadd Index",
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "messageTimestamp",
      header: "Zeitpunkt",
      cell: (info) =>
        DateTableLabel({
          timestamp: info.getValue<string>(),
        }),
      accessorFn: (row) => row.messageTimestamp,
    },
  ]
}
