import React, { ReactElement } from "react"
import { getDateString } from "@/lib/utils"

interface GpsOverviewRowProps {
  description: string
  lastSignal?: string
}

function GpsOverviewRow({
  description,
  lastSignal,
}: GpsOverviewRowProps): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-16 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{description}</h2>
        <p className="text-sm text-muted-foreground">
          {`Letzte Nachricht: ${getDateString(
            lastSignal != null ? lastSignal : "",
          )}`}
        </p>
      </div>
    </div>
  )
}

export default GpsOverviewRow
