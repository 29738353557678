import { backendApi } from "../api"

const distanceHistoryApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    listDistanceHistoryForSensor: build.query<
      ListResponse,
      ListdistanceHistoryForSensorRequest
    >({
      query: (request) => ({
        url: `sensor/distance/history/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["DistanceHistory"],
    }),
  }),
})

export const { useListDistanceHistoryForSensorQuery } = distanceHistoryApi

export interface DistanceHistoryEntry {
  id: string
  sensorId: string
  clusterId: string
  orgId: string
  serialNumber: string
  distance: number
  messageTimestamp: string
}

export interface ListResponse {
  total: number
  limit: number
  page: number
  data: DistanceHistoryEntry[]
}

export interface ListdistanceHistoryForSensorRequest {
  sensorId: string | undefined
  limit?: number
  page?: number
}
