import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useListCustomersQuery } from "@/services/customers/customers"
import createColumns from "./columns"

function CustomersTable(): ReactElement {
  const { data } = useListCustomersQuery({})

  return data != null && data.organisations != null ? (
    <div className="mx-auto py-4">
      <DataTable
        enableToolbar={false}
        columns={createColumns()}
        data={data.organisations}
      />
    </div>
  ) : (
    <div />
  )
}

export default CustomersTable
