import { backendApi } from "../api"
import { OrgAssignment } from "@/services/auth/org-assignment"

const userApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putUser: build.mutation<User, PutUserRequest>({
      query: (request) => ({
        url: `users`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Users"],
    }),
    listUsers: build.query<List, ListUsersRequest>({
      query: (request) => ({
        url: `users/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Users"],
    }),
    showUser: build.query<User, ShowUserRequest>({
      query: (request) => ({
        url: `users`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Users"],
    }),
    getUserInfo: build.query<User, GetUserInfoRequest>({
      query: (request) => ({
        url: `users/userinfo`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Users"],
    }),
  }),
})

export const {
  usePutUserMutation,
  useListUsersQuery,
  useShowUserQuery,
  useGetUserInfoQuery,
} = userApi

export interface User {
  id: string
  displayName: string
  firstName: string
  lastName: string
  mail: string
  isGlobalAdmin: boolean
  orgAssignment: OrgAssignment[]
}

export interface List {
  users: User[]
}

export interface PutUserRequest {
  user: User
}

export interface ListUsersRequest {}

export interface ShowUserRequest {
  userId: string
}

export interface GetUserInfoRequest {
  identityProviderId: string
}
