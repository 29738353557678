import React, { ReactElement } from "react"
import { Cpu, LayoutDashboardIcon, Workflow, BellRing } from "lucide-react"
import { NavLinkButton } from "@/components/nav-link-button"
import { useGetUserInfoQuery } from "@/services/users/users"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMsal } from "@azure/msal-react"

interface SideNavContentProps {
  isMobile?: boolean
}

function SideNavContent({
  isMobile = false,
}: SideNavContentProps): ReactElement {
  const { instance, accounts } = useMsal()
  const { data, isSuccess, isFetching, isLoading } = useGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { identityProviderId: accounts[0].localAccountId }
      : skipToken,
  )
  return (
    <div className="space-y-4">
      <div className="py-2">
        <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
          Sensoren
        </h2>
        <NavLinkButton
          isMobile={isMobile}
          to="dashboard"
          label="Dashboard"
          icon={<LayoutDashboardIcon className="h-4 w-4 mr-2" />}
        />
        <NavLinkButton
          isMobile={isMobile}
          to="devices"
          label="Sensorverbund"
          icon={<Cpu className="h-4 w-4 mr-2" />}
        />
        <NavLinkButton
          isMobile={isMobile}
          to="notifications"
          label="Benachrichtigungen"
          icon={<BellRing className="h-4 w-4 mr-2" />}
        />
      </div>
      <div className=" py-2">
        <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
          Workflows
        </h2>
        <NavLinkButton
          isMobile={isMobile}
          to="flows"
          label="Flows"
          icon={<Workflow className="h-4 w-4 mr-2" />}
        />
      </div>
      {data != null && data.isGlobalAdmin && (
        <div className="py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Administration
          </h2>
          <NavLinkButton
            isMobile={isMobile}
            to="device-admin"
            label="Sensorverbund"
            icon={<Cpu className="h-4 w-4 mr-2" />}
          />
        </div>
      )}
    </div>
  )
}

export default SideNavContent
