import React, { ReactElement } from "react"
import { TemplateSensor } from "@/services/templates/templates"

interface TemplateCardSensorRow {
  sensor: TemplateSensor
}

function getSensorTypeLabel(sensorType: string): string {
  if (sensorType === "SensorTypeTemp") {
    return "Temperatursensor"
  }

  if (sensorType === "SensorTypeHumidity") {
    return "Luftfeuchtigkeitssensor"
  }

  return "Unbekannt"
}

function TemplateCardSensorRow({
  sensor,
}: TemplateCardSensorRow): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-24 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{getSensorTypeLabel(sensor.sensorType)}</h2>
        <h3> {sensor.description}</h3>
        <h3>Sensor-Identifier: {sensor.sensorIdentifier}</h3>
      </div>
    </div>
  )
}

export default TemplateCardSensorRow
