import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import DateTableLabel from "@/components/date-table-label"
import { Badge } from "@/components/ui/badge"
import { DistanceHistoryEntry } from "@/services/sensors/distance-history"

export default function createColumns(): ColumnDef<DistanceHistoryEntry>[] {
  return [
    {
      accessorKey: "distance",
      header: "Abstand",
      cell: (info) => (
        <Badge className="h-8 w-24 items-center justify-center">
          {info.row.original.distance} mm
        </Badge>
      ),
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "messageTimestamp",
      header: "Zeitpunkt",
      cell: (info) =>
        DateTableLabel({
          timestamp: info.getValue<string>(),
        }),
      accessorFn: (row) => row.messageTimestamp,
    },
  ]
}
