import React, { ReactElement, useState } from "react"
import { PaginationState } from "@tanstack/react-table"
import { calculatePageCount } from "@/lib/utils"
import { PagedDataTable } from "@/components/paged-data-table"
import createColumns from "./columns"
import { useNavigate, useParams } from "react-router-dom"
import { useListWorkflowsQuery } from "@/services/workflows/workflows"

interface FlowsTablePageProps {
  orgId: string
}

function FlowsTablePage({ orgId }: FlowsTablePageProps): ReactElement {
  const nav = useNavigate()
  const [paginationState, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const { data } = useListWorkflowsQuery({
    creatorId: orgId,
  })

  return data != null && data.workflow != null ? (
    <div className="mx-auto py-4">
      <PagedDataTable
        columns={createColumns({ nav })}
        data={data.workflow}
        paginationOpts={{
          pageCount: calculatePageCount(0, paginationState.pageSize),
          enabled: false,
          paginationState,
          paginationSetter: setPagination,
        }}
      />
    </div>
  ) : (
    <div />
  )
}

export default FlowsTablePage
