import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useListGpsSensorsForClusterQuery } from "@/services/sensors/gps-sensor"
import createColumns from "./colums"

function GpsAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data } = useListGpsSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  return data != null && data.gpsSensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.gpsSensors} />
    </div>
  ) : (
    <div />
  )
}

export default GpsAdminTablePage
