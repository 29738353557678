import React, { ReactElement } from "react"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { SensorCluster } from "@/services/sensor-cluster/sensor-cluster"
import { Button } from "@/components/ui/button"
import { ArrowRight, Cpu, Heart, HeartOff } from "lucide-react"
import { ScrollArea } from "@/components/ui/scroll-area"
import TemperatureOverviewRow from "@/features/sensors/temperature/components/temperature-overview-row"
import { useListTemperatureSensorsForClusterQuery } from "@/services/sensors/temperature-sensor"
import { skipToken } from "@reduxjs/toolkit/query"
import { useNavigate } from "react-router-dom"
import { useListHumiditySensorsForClusterQuery } from "@/services/sensors/humidity-sensor"
import HumidityOverviewRow from "@/features/sensors/humidity/components/humidity-overview-row"
import { useListCarbonDioxideSensorsForClusterQuery } from "@/services/sensors/carbon-dioxide-sensor"
import CarbonDioxideOverviewRow from "@/features/sensors/carbondioxide/components/carbon-dioxide-overview-row"
import { useListOpeningSensorsForClusterQuery } from "@/services/sensors/opening-sensor"
import OpeningOverviewRow from "@/features/sensors/opening/components/opening-overview-row"
import { useListSwitchSensorsForClusterQuery } from "@/services/sensors/switch-sensor"
import SwitchOverviewRow from "@/features/sensors/switch/components/switch-overview-row"
import { useListDistanceSensorsForClusterQuery } from "@/services/sensors/distance-sensor"
import DistanceOverviewRow from "@/features/sensors/distance/components/distance-overview-row"
import { useListGpsSensorsForClusterQuery } from "@/services/sensors/gps-sensor"
import GpsOverviewRow from "@/features/sensors/gps/components/gps-overview-row"
import { useListFallDetectionSensorsForClusterQuery } from "@/services/sensors/fall-detection-sensor"

interface OnlineIndicatorProps {
  isOnline: boolean
}

function OnlineIndicator({ isOnline }: OnlineIndicatorProps): ReactElement {
  return (
    <span className="relative flex h-3 w-3 mr-2">
      <span
        className={`absolute inline-flex h-full w-full rounded-full ${
          isOnline ? "bg-green-500 animate-ping" : "bg-red-500"
        } opacity-75`}
      />
      <span
        className={`relative inline-flex rounded-full h-3 w-3 ${
          isOnline ? "bg-green-500" : "bg-red-500"
        }`}
      />
    </span>
  )
}

interface SensorClusterCardProps {
  sensorCluster: SensorCluster
}

function SensorClusterCard({
  sensorCluster,
}: SensorClusterCardProps): ReactElement {
  const nav = useNavigate()
  const { data: temperatureData } = useListTemperatureSensorsForClusterQuery(
    sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
  )

  const { data: humidityData } = useListHumiditySensorsForClusterQuery(
    sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
  )

  const { data: carbonDioxideData } =
    useListCarbonDioxideSensorsForClusterQuery(
      sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
    )

  const { data: openingData } = useListOpeningSensorsForClusterQuery(
    sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
  )

  const { data: switchData } = useListSwitchSensorsForClusterQuery(
    sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
  )

  const { data: distanceData } = useListDistanceSensorsForClusterQuery(
    sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
  )

  const { data: gpsData } = useListGpsSensorsForClusterQuery(
    sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
  )

  const { data: fallDetectionData } =
    useListFallDetectionSensorsForClusterQuery(
      sensorCluster.id != null ? { clusterId: sensorCluster.id } : skipToken,
    )

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex flex-row items-center">
          <OnlineIndicator isOnline={sensorCluster.isActive} />
          {sensorCluster.description}
        </CardTitle>
        {/* <CardDescription>
          Letzte Nachricht: {getDateString(sensorCluster.lastSignal)}
        </CardDescription> */}
      </CardHeader>
      <CardContent className="">
        <ScrollArea className="h-48">
          {temperatureData != null &&
            temperatureData.temperatureSensors != null &&
            temperatureData.temperatureSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">Temperatursensoren</h2>
                {temperatureData.temperatureSensors.map((sensor) => (
                  <TemperatureOverviewRow
                    key={sensor.id}
                    minValue={sensor.minLimit}
                    maxValue={sensor.maxLimit}
                    description={sensor.description}
                    temperature={sensor.temperature}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}

          {humidityData != null &&
            humidityData.humiditySensors != null &&
            humidityData.humiditySensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">
                  Luftfeuchtigkeitssensoren
                </h2>
                {humidityData.humiditySensors.map((sensor) => (
                  <HumidityOverviewRow
                    key={sensor.id}
                    minValue={sensor.minLimit}
                    maxValue={sensor.maxLimit}
                    description={sensor.description}
                    humidity={sensor.humidity}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}

          {carbonDioxideData != null &&
            carbonDioxideData.carbonDioxideSensors != null &&
            carbonDioxideData.carbonDioxideSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">
                  Kohlenstoffdioxidsensor
                </h2>
                {carbonDioxideData.carbonDioxideSensors.map((sensor) => (
                  <CarbonDioxideOverviewRow
                    key={sensor.id}
                    minValue={sensor.minLimit}
                    maxValue={sensor.maxLimit}
                    description={sensor.description}
                    carbonDioxide={sensor.carbonDioxide}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}

          {openingData != null &&
            openingData.openingSensors != null &&
            openingData.openingSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">Öffnungssensor</h2>
                {openingData.openingSensors.map((sensor) => (
                  <OpeningOverviewRow
                    key={sensor.id}
                    description={sensor.description}
                    opening={sensor.openingStatus}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}

          {switchData != null &&
            switchData.switchSensors != null &&
            switchData.switchSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">Schalter</h2>
                {switchData.switchSensors.map((sensor) => (
                  <SwitchOverviewRow
                    key={sensor.id}
                    description={sensor.description}
                    switchState={sensor.switchState}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}
          {distanceData != null &&
            distanceData.distanceSensors != null &&
            distanceData.distanceSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">Distanzsensoren</h2>
                {distanceData.distanceSensors.map((sensor) => (
                  <DistanceOverviewRow
                    key={sensor.id}
                    description={sensor.description}
                    distance={sensor.distance}
                    threshold={sensor.threshold}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}

          {gpsData != null &&
            gpsData.gpsSensors != null &&
            gpsData.gpsSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">GPS-Sensoren</h2>
                {gpsData.gpsSensors.map((sensor) => (
                  <GpsOverviewRow
                    key={sensor.id}
                    description={sensor.description}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}

          {fallDetectionData != null &&
            fallDetectionData.fallDetectionSensors != null &&
            fallDetectionData.fallDetectionSensors.length > 0 && (
              <div>
                <h2 className="px-2 text-lg font-bold">Sturzsensoren</h2>
                {fallDetectionData.fallDetectionSensors.map((sensor) => (
                  <GpsOverviewRow
                    key={sensor.id}
                    description={sensor.description}
                    lastSignal={sensor.lastSignal}
                  />
                ))}
              </div>
            )}
        </ScrollArea>
        <div className="mt-4 flex flex-row w-full justify-end">
          {/* <Button variant="outline" size="icon">
            {sensorCluster.isFavorite ? (
              <Heart className="h-4 w-4" />
            ) : (
              <HeartOff className="h-4 w-4" />
            )}
          </Button> */}
          <Button
            className="ml-2 mr-2"
            variant="outline"
            size="icon"
            onClick={() => nav(`${sensorCluster.id}`)}
          >
            <ArrowRight className="h-4 w-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default SensorClusterCard
