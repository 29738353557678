import React, { ReactElement, useState } from "react"
import { skipToken } from "@reduxjs/toolkit/query"
import { PaginationState } from "@tanstack/react-table"
import { calculatePageCount } from "@/lib/utils"
import { PagedDataTable } from "@/components/paged-data-table"
import { useListFallDetectionNotificationsForCustomerQuery } from "@/services/sensors/fall-detection-history"
import createColumns from "@/features/notifications/falldetection/tables/notifications/columns"
import { useListFallDetectionSensorsByCustomerQuery } from "@/services/sensors/fall-detection-sensor"
import { useListSensorClustersQuery } from "@/services/sensor-cluster/sensor-cluster"

interface FallDetectionNotificationsTablePageProps {
  orgId: string
}

function FallDetectionNotificationsTablePage({
  orgId,
}: FallDetectionNotificationsTablePageProps): ReactElement {
  const [paginationState, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: sensorClusters } = useListSensorClustersQuery(
    orgId != null && orgId !== ""
      ? {
          orgId,
        }
      : skipToken,
  )

  const { data: fallDetectionSensors } =
    useListFallDetectionSensorsByCustomerQuery(
      orgId != null && orgId !== ""
        ? {
            orgId,
          }
        : skipToken,
    )

  const { data } = useListFallDetectionNotificationsForCustomerQuery(
    orgId != null && orgId !== ""
      ? {
          orgId,
          limit: paginationState.pageSize,
          page: paginationState.pageIndex,
        }
      : skipToken,
  )

  return data != null && data.data != null ? (
    <div className="mx-auto py-4">
      <PagedDataTable
        columns={createColumns(
          sensorClusters?.sensorClusters,
          fallDetectionSensors?.fallDetectionSensors,
        )}
        data={data.data}
        paginationOpts={{
          pageCount: calculatePageCount(data.total, paginationState.pageSize),
          enabled: true,
          paginationState,
          paginationSetter: setPagination,
        }}
      />
    </div>
  ) : (
    <div />
  )
}

export default FallDetectionNotificationsTablePage
