import React, { ReactElement } from "react"
import { cn } from "@/lib/utils"
import { NavLinkButton } from "@/components/nav-link-button"

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string
    title: string
  }[]
}

export function SidebarNav({
  className,
  items,
  ...props
}: SidebarNavProps): ReactElement {
  return (
    <nav
      className={cn(
        "flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1 w-full",
        className,
      )}
      {...props}
    >
      {items.map((item) => (
        <NavLinkButton
          isMobile={false}
          label={item.title}
          to={item.href}
          key={item.href}
        />
      ))}
    </nav>
  )
}
