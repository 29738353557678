import CreateCustomerForm from "@/features/customers/forms/create-customer-form"
import CustomersTable from "@/features/customers/tables/customers-table/customers-table"
import React, { ReactElement } from "react"

function OverviewPage(): ReactElement {
  return (
    <div className="flex-col w-full">
      <CreateCustomerForm />
      <div className="mt-4 border-b" />
      <CustomersTable />
    </div>
  )
}
export default OverviewPage
