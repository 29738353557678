import React, { ReactElement, useEffect, useState } from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useParams } from "react-router-dom"
import { useListTemperatureSensorsForClusterQuery } from "@/services/sensors/temperature-sensor"
import { skipToken } from "@reduxjs/toolkit/query"
import TemperatureSensorSelection from "@/features/sensors/temperature/components/temperature-sensor-selection"
import TemperatureHistoryTablePage from "@/features/sensors/temperature/tables/history/temperature-history-table-page"
import TemperatureChart from "@/features/sensors/temperature/components/temperature-chart"
import { Card } from "@/components/ui/card"
import { useListHumiditySensorsForClusterQuery } from "@/services/sensors/humidity-sensor"
import HumiditySensorSelection from "@/features/sensors/humidity/components/humidity-sensor-selection"
import HumidityChart from "@/features/sensors/humidity/components/humidity-chart"
import HumidityHistoryTablePage from "@/features/sensors/humidity/tables/history/humidity-history-table-page"
import { useListCarbonDioxideSensorsForClusterQuery } from "@/services/sensors/carbon-dioxide-sensor"
import CarbonDioxideSensorSelection from "@/features/sensors/carbondioxide/components/carbon-dioxide-sensor-selection"
import CarbonDioxideChart from "@/features/sensors/carbondioxide/components/carbon-dioxide-chart"
import CarbonDioxideHistoryTablePage from "@/features/sensors/carbondioxide/tables/history/carbon-dioxide-history-table-page"
import OpeningOverviewPage from "@/features/sensors/opening/pages/opening-overview-page"
import SwitchOverviewPage from "@/features/sensors/switch/pages/switch-overview-page"
import { useListSwitchSensorsForClusterQuery } from "@/services/sensors/switch-sensor"
import { useListOpeningSensorsForClusterQuery } from "@/services/sensors/opening-sensor"
import { useListDistanceSensorsForClusterQuery } from "@/services/sensors/distance-sensor"
import DistanceOverviewPage from "@/features/sensors/distance/pages/distance-overview-page"
import { useListGpsSensorsForClusterQuery } from "@/services/sensors/gps-sensor"
import GpsOverviewPage from "@/features/sensors/gps/pages/gps-overview-page"
import { useListFallDetectionSensorsForClusterQuery } from "@/services/sensors/fall-detection-sensor"
import FallDetectionOverviewPage from "@/features/sensors/fallsensor/pages/fall-detection-overview-page"
import { TemperatureChartV2 } from "@/features/sensors/temperature/components/temperature-chart-v2"

function DeviceOverviewPage(): ReactElement {
  const { clusterId } = useParams()
  const [temperatureSensorId, setTemperatureSensorId] = useState("")
  const [humiditySensorId, setHumiditySensorId] = useState("")
  const [carbonDioxideSensorId, setCarbonDioxideSensorId] = useState("")

  const { data: temperatureData } = useListTemperatureSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const { data: humidityData } = useListHumiditySensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const { data: carbonDioxideData } =
    useListCarbonDioxideSensorsForClusterQuery(
      clusterId != null ? { clusterId } : skipToken,
    )

  const { data: switchSensorsData } = useListSwitchSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const { data: openingSensorsData } = useListOpeningSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const { data: distanceSensorsData } = useListDistanceSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const { data: gpsSensorData } = useListGpsSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const { data: fallDetectionData } =
    useListFallDetectionSensorsForClusterQuery(
      clusterId != null ? { clusterId } : skipToken,
    )

  useEffect(() => {
    if (
      temperatureData != null &&
      temperatureData.temperatureSensors != null &&
      temperatureData.temperatureSensors.length > 0
    ) {
      setTemperatureSensorId(temperatureData.temperatureSensors[0].id)
    }

    if (
      humidityData != null &&
      humidityData.humiditySensors != null &&
      humidityData.humiditySensors.length > 0
    ) {
      setHumiditySensorId(humidityData.humiditySensors[0].id)
    }

    if (
      carbonDioxideData != null &&
      carbonDioxideData.carbonDioxideSensors != null &&
      carbonDioxideData.carbonDioxideSensors.length > 0
    ) {
      setCarbonDioxideSensorId(carbonDioxideData.carbonDioxideSensors[0].id)
    }
  }, [temperatureData, humidityData, carbonDioxideData])

  return (
    <div className="flex-col w-full overflow-y-auto">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">
          Sensorverbund - Details
        </h2>
      </div>
      <Tabs defaultValue="gps" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            {temperatureData != null &&
              temperatureData.temperatureSensors != null &&
              temperatureData.temperatureSensors.length > 0 && (
                <TabsTrigger value="temperature">Temperatur</TabsTrigger>
              )}
            {humidityData != null &&
              humidityData.humiditySensors != null &&
              humidityData.humiditySensors.length > 0 && (
                <TabsTrigger value="humidity">Luftfeuchtigkeit</TabsTrigger>
              )}
            {carbonDioxideData != null &&
              carbonDioxideData.carbonDioxideSensors != null &&
              carbonDioxideData.carbonDioxideSensors.length > 0 && (
                <TabsTrigger value="carbonDioxide">
                  Kohlenstoffdioxide
                </TabsTrigger>
              )}
            {openingSensorsData != null &&
              openingSensorsData.openingSensors != null &&
              openingSensorsData.openingSensors.length > 0 && (
                <TabsTrigger value="opening">Öffnungssensor</TabsTrigger>
              )}
            {switchSensorsData != null &&
              switchSensorsData.switchSensors != null &&
              switchSensorsData.switchSensors.length > 0 && (
                <TabsTrigger value="switch">Schalter</TabsTrigger>
              )}
            {distanceSensorsData != null &&
              distanceSensorsData.distanceSensors != null &&
              distanceSensorsData.distanceSensors.length > 0 && (
                <TabsTrigger value="distance">Distanz</TabsTrigger>
              )}
            {gpsSensorData != null &&
              gpsSensorData.gpsSensors != null &&
              gpsSensorData.gpsSensors.length > 0 && (
                <TabsTrigger value="gps">GPS</TabsTrigger>
              )}
            {fallDetectionData != null &&
              fallDetectionData.fallDetectionSensors != null &&
              fallDetectionData.fallDetectionSensors.length > 0 && (
                <TabsTrigger value="falldetection">Sturzsensor</TabsTrigger>
              )}
          </TabsList>
        </div>
        <div className="border-b" />
        <TabsContent value="temperature">
          {temperatureData != null &&
            temperatureData.temperatureSensors != null &&
            temperatureData.temperatureSensors.length > 0 && (
              <TemperatureSensorSelection
                data={temperatureData.temperatureSensors}
                selectedId={temperatureSensorId}
                selectSensor={(value: string) => setTemperatureSensorId(value)}
              />
            )}
          {temperatureSensorId != null && (
            <Card className="p-4 my-2">
              <TemperatureChart sensorId={temperatureSensorId} />
            </Card>
          )}
          {temperatureSensorId != null && (
            <TemperatureHistoryTablePage sensorId={temperatureSensorId} />
          )}
        </TabsContent>
        <TabsContent value="humidity">
          {humidityData != null &&
            humidityData.humiditySensors != null &&
            humidityData.humiditySensors.length > 0 && (
              <HumiditySensorSelection
                data={humidityData.humiditySensors}
                selectedId={humiditySensorId}
                selectSensor={(value: string) => setHumiditySensorId(value)}
              />
            )}
          {humiditySensorId != null && (
            <Card className="p-4 my-2">
              <HumidityChart sensorId={humiditySensorId} />
            </Card>
          )}
          {humiditySensorId != null && (
            <HumidityHistoryTablePage sensorId={humiditySensorId} />
          )}
        </TabsContent>
        <TabsContent value="carbonDioxide">
          {carbonDioxideData != null &&
            carbonDioxideData.carbonDioxideSensors != null &&
            carbonDioxideData.carbonDioxideSensors.length > 0 && (
              <CarbonDioxideSensorSelection
                data={carbonDioxideData.carbonDioxideSensors}
                selectedId={carbonDioxideSensorId}
                selectSensor={(value: string) =>
                  setCarbonDioxideSensorId(value)
                }
              />
            )}
          {carbonDioxideSensorId != null && (
            <Card className="p-4 my-2">
              <CarbonDioxideChart sensorId={carbonDioxideSensorId} />
            </Card>
          )}
          {carbonDioxideSensorId != null && (
            <CarbonDioxideHistoryTablePage sensorId={carbonDioxideSensorId} />
          )}
        </TabsContent>
        <TabsContent value="opening">
          {clusterId != null && <OpeningOverviewPage clusterId={clusterId} />}
        </TabsContent>
        <TabsContent value="switch">
          {clusterId != null && <SwitchOverviewPage clusterId={clusterId} />}
        </TabsContent>
        <TabsContent value="distance">
          {clusterId != null && <DistanceOverviewPage clusterId={clusterId} />}
        </TabsContent>
        <TabsContent value="gps">
          {clusterId != null && <GpsOverviewPage clusterId={clusterId} />}
        </TabsContent>
        <TabsContent value="falldetection">
          {clusterId != null && (
            <FallDetectionOverviewPage clusterId={clusterId} />
          )}
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default DeviceOverviewPage
