import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useListDistanceSensorsForClusterQuery } from "@/services/sensors/distance-sensor"
import createColumns from "./columns"

function DistanceAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data } = useListDistanceSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )
  return data != null && data.distanceSensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.distanceSensors} />
    </div>
  ) : (
    <div />
  )
}

export default DistanceAdminTablePage
