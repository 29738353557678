import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { usePutCustomerMutation } from "@/services/customers/customers"

const formSchema = z.object({
  description: z.string(),
  portalUrl: z.string(),
})

function CreateCustomerForm(): ReactElement {
  const [open, setOpen] = useState(false)
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      portalUrl: "",
    },
  })

  const [createCustomer, { isSuccess }] = usePutCustomerMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    createCustomer({
      id: uuidv4(),
      description: values.description,
      portalUrl: values.portalUrl,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="outline">Kunden anlegen</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Neuen Kunden anlegen</DialogTitle>
          <DialogDescription>Legen Sie einen neuen Kunden an</DialogDescription>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Beschreibung" {...field} />
                    </FormControl>
                    <FormDescription>Der Name für den Kunden</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="portalUrl"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Portal Url</FormLabel>
                    <FormControl>
                      <Input placeholder="Portal URL" {...field} />
                    </FormControl>
                    <FormDescription>
                      Die Kennung, über die das Frontend erreichbar gemacht
                      werden soll
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateCustomerForm
