import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import React, { ReactElement } from "react"
import { useListTemplatesQuery } from "@/services/templates/templates"
import TemplateCard from "@/features/templates/components/template-card"

function TemplatesPage(): ReactElement {
  const { data: templates } = useListTemplatesQuery({})

  return (
    <div className="flex-col w-full">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">Templates</h2>
      </div>
      <Tabs defaultValue="highlights" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="highlights">Highlights</TabsTrigger>
            <TabsTrigger value="manufacturer">Hersteller</TabsTrigger>
          </TabsList>
        </div>
        <div className="border-b" />
        <TabsContent
          value="highlights"
          className="grid gap-4 2xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1"
        >
          {templates != null &&
            templates.templates != null &&
            templates.templates.length > 0 &&
            templates.templates.map((template) => (
              <TemplateCard template={template} />
            ))}
        </TabsContent>
        <TabsContent value="manufacturer" />
      </Tabs>
    </div>
  )
}

export default TemplatesPage
