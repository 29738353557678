/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { v4 as uuidv4 } from "uuid"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Checkbox } from "@/components/ui/checkbox"
import { usePutSensorClusterMutation } from "@/services/sensor-cluster/sensor-cluster"
import { useParams } from "react-router-dom"

const formSchema = z.object({
  description: z.string(),
  isActive: z.boolean(),
  isFavorite: z.boolean(),
  protocol: z.string(),
})

function CreateClusterForm(): ReactElement {
  const { orgId } = useParams()
  const [open, setOpen] = useState(false)
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      isActive: false,
      isFavorite: false,
      protocol: "LoraProtocol",
    },
  })

  const [createSensorCluster, { isSuccess }] = usePutSensorClusterMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    createSensorCluster({
      sensorCluster: {
        id: uuidv4(),
        orgId: orgId ? orgId : "",
        description: values.description,
        protocol: values.protocol,
        isActive: values.isActive,
        isFavorite: values.isFavorite,
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Verbund anlegen</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Neuen Verbund anlegen</DialogTitle>
          <DialogDescription>
            Legen Sie einen neuen Verbund an. Im Anschluss können Sie Sensoren
            zu diesem Verbund hinzufügen
          </DialogDescription>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="protocol"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Protokoll</FormLabel>
                    <FormControl>
                      <Select
                        value={field.value}
                        defaultValue="LoraProcol"
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Bitte Protokoll auswählen" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="LoraProtocol">Lora</SelectItem>
                          <SelectItem value="EnOcean">EnOcean</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription>Protokol des Sensors</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Beschreibung" {...field} />
                    </FormControl>
                    <FormDescription>Der Name für den Verbund</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isActive"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Soll der Verbund aktiv sein?</FormLabel>
                    <FormControl>
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="isActive"
                          onCheckedChange={() => field.onChange(!field.value)}
                        />
                        <label
                          htmlFor="isActive"
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Ist der Sensor aktiv?
                        </label>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isFavorite"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Soll der Verbund favorisiert werden?</FormLabel>
                    <FormControl>
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="isFavorite"
                          onCheckedChange={() => field.onChange(!field.value)}
                        />
                        <label
                          htmlFor="isFavorite"
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Ist der Verbund ein Favorit?
                        </label>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateClusterForm
