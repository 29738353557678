import { backendApi } from "../api"

const humiditySensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putHumiditySensor: build.mutation<HumiditySensor, PutHumiditySensorRequest>(
      {
        query: (request) => ({
          url: `sensor/humidity`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["Humidity"],
      },
    ),
    listHumiditySensorsForCluster: build.query<
      HumiditySensorList,
      ListHumiditySensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/humidity/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Humidity"],
    }),
  }),
})

export const {
  usePutHumiditySensorMutation,
  useListHumiditySensorsForClusterQuery,
} = humiditySensorApi

export interface HumiditySensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  humidity: number
  minLimit: number
  maxLimit: number
  lastSignal?: string
  modelNumber?: string
}

export interface HumiditySensorList {
  humiditySensors: HumiditySensor[]
}

export interface PutHumiditySensorRequest {
  humiditySensor: HumiditySensor
}

export interface ListHumiditySensorsForClusterRequest {
  clusterId: string | undefined
}
