import { backendApi } from "../api"

const orgAssignment = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putAssignment: build.mutation<OrgAssignment, PutOrgAssignmentRequest>({
      query: (request) => ({
        url: `organisations/assignments`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["OrgAssignments"],
    }),
    listAssignments: build.query<List, ListOrgAssignmentsRequest>({
      query: (request) => ({
        url: `organisations/assignments/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["OrgAssignments"],
    }),
    getAssignmentsForUser: build.query<List, GetOrgAssignmentsForUserRequest>({
      query: (request) => ({
        url: `organisations/assignments/user`,
        method: "GET",
        params: request,
      }),
      providesTags: ["OrgAssignments"],
    }),
    getAssignmentsForOrg: build.query<List, GetOrgAssignmentsForOrgRequest>({
      query: (request) => ({
        url: `organisations/assignments/org`,
        method: "GET",
        params: request,
      }),
      providesTags: ["OrgAssignments"],
    }),
  }),
})

export const {
  usePutAssignmentMutation,
  useListAssignmentsQuery,
  useGetAssignmentsForOrgQuery,
  useGetAssignmentsForUserQuery,
} = orgAssignment

export interface OrgAssignment {
  id: string
  userId: string
  orgId: string
  role: string
}

export interface List {
  orgAssignments: OrgAssignment[]
}

export interface PutOrgAssignmentRequest {
  orgAssignment: OrgAssignment
}

export interface ListOrgAssignmentsRequest {}

export interface GetOrgAssignmentsForUserRequest {
  userId: string
}

export interface GetOrgAssignmentsForOrgRequest {
  orgId: string
}
