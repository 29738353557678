import React, { ReactElement } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { OpeningSensor } from "@/services/sensors/opening-sensor"

interface OpeningSensorSelectionProps {
  selectedId: string
  selectSensor: (sensorId: string) => void
  data: OpeningSensor[]
}

function OpeningSensorSelection({
  selectedId,
  selectSensor,
  data,
}: OpeningSensorSelectionProps): ReactElement {
  return (
    <Select
      defaultValue={selectedId}
      onValueChange={(value) => selectSensor(value)}
    >
      <SelectTrigger>
        <SelectValue placeholder="Öffungssensor" />
      </SelectTrigger>
      <SelectContent>
        {data.map((openingSensor) => (
          <SelectItem key={openingSensor.id} value={openingSensor.id}>
            {openingSensor.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default OpeningSensorSelection
