import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { usePutCustomerMutation } from "@/services/customers/customers"
import { usePutUserMutation } from "@/services/users/users"

const formSchema = z.object({
  displayName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  mail: z.string(),
})

function CreateCustomerForm(): ReactElement {
  const [open, setOpen] = useState(false)
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: "",
      firstName: "",
      lastName: "",
      mail: "",
    },
  })

  const [createUser, { isSuccess }] = usePutUserMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    createUser({
      user: {
        id: uuidv4(),
        displayName: values.displayName,
        firstName: values.firstName,
        lastName: values.lastName,
        mail: values.mail,
        isGlobalAdmin: false,
        orgAssignment: [],
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Nutzer anlegen</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Neuen Nutzer anlegen</DialogTitle>
          <DialogDescription>Legen Sie einen neuen Nutzer an</DialogDescription>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Anzeigename</FormLabel>
                    <FormControl>
                      <Input placeholder="Anzeigename" {...field} />
                    </FormControl>
                    <FormDescription>
                      Der Name, der im System angezeigt wird
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Vorname</FormLabel>
                    <FormControl>
                      <Input placeholder="Vorname" {...field} />
                    </FormControl>
                    <FormDescription>Der Vorname des Nutzers</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nachname</FormLabel>
                    <FormControl>
                      <Input placeholder="Nachname" {...field} />
                    </FormControl>
                    <FormDescription>Der Nachname des Nutzers</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="mail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mail</FormLabel>
                    <FormControl>
                      <Input placeholder="Mail" {...field} />
                    </FormControl>
                    <FormDescription>Mail des Nutzers</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateCustomerForm
