import { ColumnDef } from "@tanstack/react-table"
import { IdentityLink } from "@/services/auth/identity-link"

export default function createColumns(): ColumnDef<IdentityLink>[] {
  return [
    {
      accessorKey: "userId",
      header: "Nutzer Id",
    },
    {
      accessorKey: "identityProviderId",
      header: "Identity Provider Id",
    },
    {
      accessorKey: "identityLinkType",
      header: "Identity Link Type",
    },
  ]
}
