import React, { ReactElement } from "react"
import { Badge } from "@/components/ui/badge"
import { getDateString } from "@/lib/utils"

interface CarbonDioxideOverviewRowProps {
  description: string
  carbonDioxide: number
  lastSignal?: string
  minValue: number
  maxValue: number
}

function isValid(
  humidity: number,
  minValue: number,
  maxValue: number,
): boolean {
  if (humidity > minValue && humidity < maxValue) {
    return true
  }

  return false
}

function CarbonDioxideOverviewRow({
  description,
  carbonDioxide,
  minValue,
  maxValue,
  lastSignal,
}: CarbonDioxideOverviewRowProps): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-16 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{description}</h2>
        <p className="text-sm text-muted-foreground">
          Letzte Nachricht: {getDateString(lastSignal!)}
        </p>
      </div>
      <Badge
        variant={
          isValid(carbonDioxide, minValue, maxValue) ? "default" : "destructive"
        }
        className="h-8"
      >
        {carbonDioxide} ppm
      </Badge>
    </div>
  )
}

export default CarbonDioxideOverviewRow
