import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { useListTemperatureSensorsForClusterQuery } from "@/services/sensors/temperature-sensor"
import { skipToken } from "@reduxjs/toolkit/query"
import createColumns from "@/features/sensors/temperature/tables/admin-table/columns"

function TemperatureAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data, isLoading } = useListTemperatureSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  return data != null && data.temperatureSensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.temperatureSensors} />
    </div>
  ) : (
    <div />
  )
}

export default TemperatureAdminTablePage
