import { ColumnDef } from "@tanstack/react-table"
import { Customer } from "@/services/customers/customers"

export default function createColumns(): ColumnDef<Customer>[] {
  return [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "description",
      header: "Beschreibung",
    },
    {
      accessorKey: "portalUrl",
      header: "Portal Url",
    },
  ]
}
