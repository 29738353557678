import React, { ReactElement } from "react"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"

interface ActionProps {
  icon: ReactElement
  title: string
  content: ReactElement
  description: string
  onClick: () => void
}

function ActionSheetRowItem({
  title,
  icon,
  content,
  description,
  onClick,
}: ActionProps): ReactElement {
  return (
    <Card>
      <CardHeader>
        <div className="flex flex-row space-x-8 items-center w-full">
          <div className="flex flex-col space-y-2">
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        {content}
        <Button onClick={onClick} className="w-full">
          Action hinzufügen
        </Button>
      </CardContent>
    </Card>
  )
}

export default ActionSheetRowItem
