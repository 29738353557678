import { backendApi } from "../api"

const distanceSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putDistanceSensor: build.mutation<DistanceSensor, PutDistanceSensorRequest>(
      {
        query: (request) => ({
          url: `sensor/distance`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["Distance"],
      },
    ),
    listDistanceSensorsForCluster: build.query<
      DistanceSensorList,
      ListDistanceSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/distance/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Distance"],
    }),
    listDistanceSensors: build.query<
      DistanceSensorList,
      ListDistanceSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/distance/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Distance"],
    }),
  }),
})

export const {
  usePutDistanceSensorMutation,
  useListDistanceSensorsForClusterQuery,
  useListDistanceSensorsQuery,
} = distanceSensorApi

export interface DistanceSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  distance: number
  threshold: number
  lastSignal?: string
  modelNumber?: string
}

export interface DistanceSensorList {
  distanceSensors: DistanceSensor[]
}

export interface PutDistanceSensorRequest {
  distanceSensor: DistanceSensor
}

export interface ListDistanceSensorsForClusterRequest {
  clusterId?: string
}
