import React, { ReactElement } from "react"
import { useListTemperatureSensorsForClusterQuery } from "@/services/sensors/temperature-sensor"
import { skipToken } from "@reduxjs/toolkit/query"
import TemperatureDashboardCard from "./temperature-dashboard-card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel"

interface TemperatureDashboardComponentProps {
  clusterId?: string
}

function TemperatureDashboardComponent({
  clusterId,
}: TemperatureDashboardComponentProps): ReactElement {
  const { data: temperatureData } = useListTemperatureSensorsForClusterQuery(
    clusterId != null && clusterId !== "" ? { clusterId } : skipToken,
  )

  if (temperatureData == null || temperatureData?.temperatureSensors == null) {
    return <div />
  }

  return (
    <div className="w-full flex-col">
      <h1 className="text-2xl font-bold mb-2">Temperatursensor</h1>
      {temperatureData.temperatureSensors.length !== 0 && (
        <Carousel>
          <CarouselContent>
            {temperatureData.temperatureSensors.map((sensor) => {
              return (
                <CarouselItem className="basis-1/3">
                  <TemperatureDashboardCard tempSensor={sensor} />
                </CarouselItem>
              )
            })}
          </CarouselContent>
        </Carousel>
      )}
    </div>
  )
}

export default TemperatureDashboardComponent
