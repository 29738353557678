import React, { ReactElement } from "react"
import LinkTable from "@/features/identity-link/tables/link-table/link-table"
import CreateIdentityLinkForm from "@/features/identity-link/forms/create-identity-link-form"

function IdentityLinkPage(): ReactElement {
  return (
    <div className="flex-col w-full">
      <CreateIdentityLinkForm />
      <div className="mt-4 border-b" />
      <LinkTable />
    </div>
  )
}
export default IdentityLinkPage
