import React, { ReactElement } from "react"
import { skipToken } from "@reduxjs/toolkit/query"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel"
import { useListCarbonDioxideSensorsForClusterQuery } from "@/services/sensors/carbon-dioxide-sensor"
import CarbonDioxideDashboardCard from "@/features/sensors/carbondioxide/components/carbon-dioxide-dashboard-card"

interface CarbonDioxideDashboardComponentProps {
  clusterId?: string
}

function CarbonDioxideDashboardComponent({
  clusterId,
}: CarbonDioxideDashboardComponentProps): ReactElement {
  const { data: clusterData } = useListCarbonDioxideSensorsForClusterQuery(
    clusterId != null && clusterId !== "" ? { clusterId } : skipToken,
  )

  if (
    clusterData == null ||
    clusterData?.carbonDioxideSensors == null ||
    clusterData?.carbonDioxideSensors.length === 0
  ) {
    return <div />
  }

  return (
    <div className="w-full flex-col">
      <h1 className="text-2xl font-bold mb-2">CO2-Sensoren</h1>
      <Carousel className="w-full">
        <CarouselContent>
          {clusterData.carbonDioxideSensors.map((sensor) => {
            return (
              <CarouselItem className="basis-1/3">
                <CarbonDioxideDashboardCard carbonDioxideSensor={sensor} />
              </CarouselItem>
            )
          })}
        </CarouselContent>
      </Carousel>
    </div>
  )
}

export default CarbonDioxideDashboardComponent
