import React, { ReactElement } from "react"
import { Badge } from "@/components/ui/badge"
import { getDateString } from "@/lib/utils"

interface TemperatureOverviewRowProps {
  description: string
  temperature: number
  lastSignal?: string
  minValue: number
  maxValue: number
}

function isValid(
  temperature: number,
  minValue: number,
  maxValue: number,
): boolean {
  if (temperature > minValue && temperature < maxValue) {
    return true
  }

  return false
}

function TemperatureOverviewRow({
  description,
  temperature,
  minValue,
  maxValue,
  lastSignal,
}: TemperatureOverviewRowProps): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-16 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{description}</h2>
        <p className="text-sm text-muted-foreground">
          Letzte Nachricht: {getDateString(lastSignal!)}
        </p>
      </div>
      <Badge
        variant={
          isValid(temperature, minValue, maxValue) ? "default" : "destructive"
        }
        className="h-8"
      >
        {temperature} &#8451;
      </Badge>
    </div>
  )
}

export default TemperatureOverviewRow
