import { ColumnDef } from "@tanstack/react-table"
import { TemperatureHistoryEntry } from "@/services/sensors/temperature-history"
import DateTableLabel from "@/components/date-table-label"

export default function createColumns(): ColumnDef<TemperatureHistoryEntry>[] {
  return [
    {
      accessorKey: "temperature",
      header: "Temperatur",
      cell: (info) => `${info.row.original.temperature} °C`,
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "messageTimestamp",
      header: "Zeitpunkt",
      cell: (info) =>
        DateTableLabel({
          timestamp: info.getValue<string>(),
        }),
      accessorFn: (row) => row.messageTimestamp,
    },
  ]
}
