import React, { ReactElement } from "react"
import { Button } from "@/components/ui/button"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "@/authConfig"
import { ComputerIcon } from "lucide-react"
import logo from "../../assets/logo_black.png"

export function LoginPage(): ReactElement {
  const { instance } = useMsal()
  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
      })
      .catch((error) => console.log(error))
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-black text-primary-foreground">
      <div className="lg:w-[500px] space-y-6 rounded-lg bg-card p-8 shadow-lg">
        <div className="space-y-2 flex flex-col justify-center text-center items-center">
          <img alt="logo" src={logo} className={"w-64 p-4"} />
          <h1 className="text-3xl font-bold text-black">Anmelden</h1>
          <p className="text-muted-foreground">
            Melden Sie sich zum Fortfahren mit Ihrem Konto an
          </p>
        </div>
        <Button
          onClick={handleRedirect}
          className="inline-flex w-full items-center justify-center rounded-md bg-secondary px-4 py-2 text-sm font-medium text-secondary-foreground transition-colors hover:bg-secondary/90 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
        >
          <ComputerIcon className="mr-2 h-5 w-5" />
          Anmelden mit Microsoft
        </Button>
      </div>
    </div>
  )
}
