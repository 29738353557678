import { backendApi } from "../api"

const switchSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putSwitchSensor: build.mutation<SwitchSensor, PutSwitchSensorRequest>({
      query: (request) => ({
        url: `sensor/switch`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Switch"],
    }),
    sendSensorDownlink: build.mutation<
      SendSensorDownlinkRequest,
      SendSensorDownlinkResponse
    >({
      query: (request) => ({
        url: `sensor/switch/downlink/send`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Switch"],
    }),
    listSwitchSensorsForCluster: build.query<
      SwitchSensorList,
      ListSwitchSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/switch/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Switch"],
    }),
    listSwitchSensors: build.query<
      SwitchSensorList,
      ListSwitchSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/switch/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Switch"],
    }),
  }),
})

export const {
  usePutSwitchSensorMutation,
  useListSwitchSensorsForClusterQuery,
  useListSwitchSensorsQuery,
  useSendSensorDownlinkMutation,
} = switchSensorApi

export interface SwitchSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  switchState: boolean
  lastSignal?: string
  modelNumber?: string
  applicationId: string
  deviceId: string
  stateIsDirty: boolean
}

export interface SwitchSensorList {
  switchSensors: SwitchSensor[]
}

export interface PutSwitchSensorRequest {
  switchSensor: SwitchSensor
}

export interface ListSwitchSensorsForClusterRequest {
  clusterId?: string
}

export interface SendSensorDownlinkRequest {
  id: string
  switchState: boolean
}

export interface SendSensorDownlinkResponse {}
