import { backendApi } from "../api"

const temperatureHistoryApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    listTemperatureHistoryForSensor: build.query<
      ListResponse,
      ListTemperatureHistoryForSensorRequest
    >({
      query: (request) => ({
        url: `sensor/temperature/history/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["TemperatureHistory"],
    }),
    listTemperatureNotificationsForCluster: build.query<
      ListTemperatureNotificationsForClusterResponse,
      ListTemperatureNotificationsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/temperature/notifications/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["TemperatureHistory"],
    }),
  }),
})

export const {
  useListTemperatureHistoryForSensorQuery,
  useListTemperatureNotificationsForClusterQuery,
} = temperatureHistoryApi

export interface TemperatureHistoryEntry {
  id: string
  sensorId: string
  clusterId: string
  orgId: string
  serialNumber: string
  temperature: number
  messageTimestamp: string
}

export interface ListResponse {
  total: number
  limit: number
  page: number
  data: TemperatureHistoryEntry[]
}

export interface ListTemperatureHistoryForSensorRequest {
  sensorId: string | undefined
  limit?: number
  page?: number
}

export interface NotificationEntry {
  id: string
  sensorId: string
  clusterId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  temperature: number
  minLimit: number
  maxLimit: number
  timestamp?: string
  modelNumber?: string
}

export interface ListTemperatureNotificationsForClusterResponse {
  total: number
  limit: number
  page: number
  data: NotificationEntry[]
}

export interface ListTemperatureNotificationsForClusterRequest {
  clusterId: string | undefined
  limit?: number
  page?: number
}
