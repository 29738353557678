import { Button } from "@/components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { CirclePlus } from "lucide-react"
import React, { ReactElement } from "react"

export default function IntegrationPage(): ReactElement {
  return (
    <div className="flex-col w-full">
      <Tabs defaultValue="azure" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="azure">Azure</TabsTrigger>
            <TabsTrigger value="aws">AWS</TabsTrigger>
          </TabsList>
          <Button variant="outline">
            <div className="flex flex-row justify-center space-x-2 items-center">
              <CirclePlus />
              <p>Neue Verknüpfung anlegen</p>
            </div>
          </Button>
        </div>
        <div className="border-b" />
        <TabsContent
          value="azure"
          className="grid gap-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1"
        />
      </Tabs>
    </div>
  )
}
