import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import React, { ReactElement, useEffect } from "react"
import { useListSensorClustersQuery } from "@/services/sensor-cluster/sensor-cluster"
import { Skeleton } from "@/components/ui/skeleton"
import SensorClusterCard from "@/features/sensorcluster/components/sensorClusterCard"
import { useParams } from "react-router-dom"

function DevicesPage(): ReactElement {
  const { orgId } = useParams()
  const { data, isLoading } = useListSensorClustersQuery({ orgId })

  return (
    <div className="flex-col w-full">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">Sensorverbund</h2>
      </div>
      <Tabs defaultValue="overview" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="overview">Übersicht</TabsTrigger>
            {/* <TabsTrigger value="notifications">Benachrichtigungen</TabsTrigger> */}
          </TabsList>
        </div>
        <div className="border-b" />
        <TabsContent
          value="overview"
          className="grid gap-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1"
        >
          {data != null && data.sensorClusters != null && !isLoading ? (
            data.sensorClusters.map((cluster) => (
              <SensorClusterCard key={cluster.id} sensorCluster={cluster} />
            ))
          ) : (
            <Skeleton />
          )}
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default DevicesPage
