import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useEffect, useState } from "react"
import FallDetectionSensorSelection from "../components/fall-detection-sensor-selection"
import FallDetectionHistoryTablePage from "../tables/history/fall-detection-history-table-page"
import { useListFallDetectionSensorsForClusterQuery } from "@/services/sensors/fall-detection-sensor"

interface GpsOverviewPageProps {
  clusterId: string
}

function FallDetectionOverviewPage({
  clusterId,
}: GpsOverviewPageProps): ReactElement {
  const [sensorId, setSensorId] = useState("")
  const { data: fallDetectionData } =
    useListFallDetectionSensorsForClusterQuery(
      clusterId != null ? { clusterId } : skipToken,
    )

  useEffect(() => {
    if (
      fallDetectionData != null &&
      fallDetectionData.fallDetectionSensors != null &&
      fallDetectionData.fallDetectionSensors.length > 0
    ) {
      setSensorId(fallDetectionData.fallDetectionSensors[0].id)
    }
  }, [fallDetectionData])

  return (
    <div className="flex flex-col w-full">
      {fallDetectionData != null &&
        fallDetectionData.fallDetectionSensors != null &&
        fallDetectionData.fallDetectionSensors.length > 0 &&
        sensorId != null &&
        sensorId !== "" && (
          <FallDetectionSensorSelection
            data={fallDetectionData.fallDetectionSensors}
            selectedId={sensorId}
            selectSensor={(value: string) => setSensorId(value)}
          />
        )}
      {sensorId != null && (
        <FallDetectionHistoryTablePage sensorId={sensorId} />
      )}
    </div>
  )
}

export default FallDetectionOverviewPage
