import { backendApi } from "../api"

const sensorClusterApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putSensorCluster: build.mutation<SensorCluster, PutSensorClusterRequest>({
      query: (request) => ({
        url: `sensorcluster`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SensorCluster"],
    }),
    listSensorClusters: build.query<List, ListSensorClustersRequest>({
      query: (request) => ({
        url: `sensorcluster/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["SensorCluster"],
    }),
  }),
})

export const { useListSensorClustersQuery, usePutSensorClusterMutation } =
  sensorClusterApi

export interface SensorCluster {
  id: string
  orgId: string
  description: string
  protocol: string
  isActive: boolean
  isFavorite: boolean
}

export interface List {
  sensorClusters: SensorCluster[]
}

export interface PutSensorClusterRequest {
  sensorCluster: SensorCluster
}

export interface ListSensorClustersRequest {
  orgId: string | undefined
}
