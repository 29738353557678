import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import createColumns from "./colums"
import { useListFallDetectionSensorsForClusterQuery } from "@/services/sensors/fall-detection-sensor"

function FallDetectionAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data } = useListFallDetectionSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  return data != null && data.fallDetectionSensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.fallDetectionSensors} />
    </div>
  ) : (
    <div />
  )
}

export default FallDetectionAdminTablePage
