import { backendApi } from "../api"

const carbonDioxideHistoryApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    listCarbonDioxideHistoryForSensor: build.query<
      ListResponse,
      ListCarbonDioxideHistoryForSensorRequest
    >({
      query: (request) => ({
        url: `sensor/carbondioxide/history/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["CarbonDioxideHistory"],
    }),
  }),
})

export const { useListCarbonDioxideHistoryForSensorQuery } =
  carbonDioxideHistoryApi

export interface CarbonDioxideHistoryEntry {
  id: string
  sensorId: string
  clusterId: string
  orgId: string
  serialNumber: string
  carbonDioxide: number
  messageTimestamp: string
}

export interface ListResponse {
  total: number
  limit: number
  page: number
  data: CarbonDioxideHistoryEntry[]
}

export interface ListCarbonDioxideHistoryForSensorRequest {
  sensorId: string | undefined
  limit?: number
  page?: number
}
