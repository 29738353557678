import { backendApi } from "../api"

const openingHistoryApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    listOpeningHistoryForSensor: build.query<
      ListResponse,
      ListOpeningHistoryForSensorRequest
    >({
      query: (request) => ({
        url: `sensor/opening/history/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["OpeningHistory"],
    }),
  }),
})

export const { useListOpeningHistoryForSensorQuery } = openingHistoryApi

export interface OpeningHistoryEntry {
  id: string
  sensorId: string
  clusterId: string
  orgId: string
  serialNumber: string
  openingStatus: number
  messageTimestamp: string
}

export interface ListResponse {
  total: number
  limit: number
  page: number
  data: OpeningHistoryEntry[]
}

export interface ListOpeningHistoryForSensorRequest {
  sensorId: string | undefined
  limit?: number
  page?: number
}
