import React, { ReactElement } from "react"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Handle, Position } from "reactflow"

interface ActionCardNodeProps {
  title: string
  description: string
  withTarget: boolean
  withSource: boolean
}

function ActionCardNode({
  title,
  description,
  withTarget,
  withSource,
}: ActionCardNodeProps): ReactElement {
  return (
    <div>
      {withTarget && <Handle type="target" position={Position.Right} />}
      {withSource && <Handle type="source" position={Position.Left} />}
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
      </Card>
    </div>
  )
}

export function TemperatureCardNode() {
  return (
    <ActionCardNode
      title={"Temperaturverstoß"}
      description={
        "Sobald die Temperatur Grenzwerte verletzt trigger diese Action"
      }
      withTarget={true}
      withSource={false}
    />
  )
}

export function MailCardNode() {
  return (
    <ActionCardNode
      title={"Mail verschicken"}
      description={"Diese Aktion schickt eine Benachrichtigungsmail raus"}
      withTarget={false}
      withSource={true}
    />
  )
}

export default ActionCardNode
