import React, { ReactElement, useEffect, useState } from "react"
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  CarbonDioxideHistoryEntry,
  useListCarbonDioxideHistoryForSensorQuery,
} from "@/services/sensors/carbon-dioxide-history"
import { render } from "@testing-library/react"

interface CarbonDioxideChartProps {
  sensorId: string
}

function CarbonDioxideChart({
  sensorId,
}: CarbonDioxideChartProps): ReactElement {
  const { data: historyData } = useListCarbonDioxideHistoryForSensorQuery(
    sensorId != null ? { sensorId } : skipToken,
  )
  const [renderData, setRenderData] = useState<CarbonDioxideHistoryEntry[]>([])

  useEffect(() => {
    if (
      historyData != null &&
      historyData.data != null &&
      historyData.data.length > 0
    ) {
      setRenderData([...historyData.data].reverse())
    }
  }, [historyData])

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={renderData}>
        <XAxis
          dataKey="messageTimestamp"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => `${value}`}
        />
        <Line dataKey="carbonDioxide" fill="#adfa1d" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default CarbonDioxideChart
