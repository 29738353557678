import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Skeleton } from "@/components/ui/skeleton"
import TemperatureDashboardComponent from "@/features/sensors/temperature/components/temperature-dashboard-component"
import { useListSensorClustersQuery } from "@/services/sensor-cluster/sensor-cluster"
import React, { ReactElement, useEffect, useState } from "react"
import FallSensorDashboardComponent from "@/features/sensors/fallsensor/components/fall-sensor-dashboard-component"
import PageContainer from "@/components/page-container"
import CarbonDioxideDashboardComponent from "@/features/sensors/carbondioxide/components/carbon-dioxide-dashboard-component"
import { useParams } from "react-router-dom"

function DashboardV2(): ReactElement {
  const [currentCluster, setCurrentCluster] = useState("")
  const { orgId } = useParams()
  const { data, isLoading, isFetching } = useListSensorClustersQuery({
    orgId: orgId,
  })

  useEffect(() => {
    if (
      data != null &&
      data.sensorClusters != null &&
      data.sensorClusters.length > 0
    ) {
      setCurrentCluster(data.sensorClusters[0].id)
    }
  }, [data])

  if (
    data == null ||
    data.sensorClusters == null ||
    data.sensorClusters.length === 0
  ) {
    return (
      <PageContainer title="Dashboard">
        <div className="w-full h-full items-center justify-center">
          <h2 className="font-bold text-3xl">Keine Daten vorhanden</h2>
        </div>
      </PageContainer>
    )
  }

  return (
    <PageContainer title="Dashboard">
      <div className="w-full">
        {(isLoading || isFetching) && data != null ? (
          <Skeleton className="w-[180px] rounded-md" />
        ) : (
          <div className="flex flex-col space-y-4 ">
            <Select
              value={currentCluster}
              onValueChange={(value) => {
                setCurrentCluster(value)
              }}
            >
              <SelectTrigger className="lg:w-[180px] w-full">
                <SelectValue placeholder="Sensor Cluster" />
              </SelectTrigger>
              <SelectContent>
                {data?.sensorClusters.map((cluster) => {
                  return (
                    <SelectItem key={cluster.id} value={cluster.id}>
                      {cluster.description}
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
            <TemperatureDashboardComponent clusterId={currentCluster} />
            <CarbonDioxideDashboardComponent clusterId={currentCluster} />
            <FallSensorDashboardComponent
              clusterId={currentCluster}
              orgId={orgId}
            />
          </div>
        )}
      </div>
    </PageContainer>
  )
}

export default DashboardV2
