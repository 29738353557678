import React, { ReactElement, ReactNode } from "react"
import { NavLink } from "react-router-dom"

interface NavLinkButtonProps {
  to: string
  label: string
  icon?: ReactElement
  isMobile: boolean
}

export function NavLinkButton({
  label,
  icon,
  to,
  isMobile,
}: NavLinkButtonProps): ReactElement {
  if (isMobile) {
    return (
      <div className="space-y-1">
        <NavLink
          to={to}
          className={({ isActive, isPending }) => {
            return `${
              isActive ? "bg-muted" : ""
            } mx-[-0.65rem] flex items-center gap-4 rounded-xl  px-3 py-2 text-foreground hover:text-foreground`
          }}
        >
          {icon}
          {label}
        </NavLink>
      </div>
    )
  }

  return (
    <div className="space-y-1">
      <NavLink
        to={to}
        className={({ isActive, isPending }) => {
          return `${
            isActive ? "bg-muted" : ""
          } flex items-center gap-3 rounded-lg px-3 py-2 text-primary transition-all hover:text-primary`
        }}
      >
        {label != null && icon}
        {label}
      </NavLink>
    </div>
  )
}
