import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import { Search } from "lucide-react"
import { NavLink } from "react-router-dom"
import { DistanceSensor } from "@/services/sensors/distance-sensor"

export default function createColumns(): ColumnDef<DistanceSensor>[] {
  return [
    {
      accessorKey: "description",
      header: "Beschreibung",
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "id",
      header: "Details",
      cell: (info) => (
        <NavLink to={`/device-admin/${info.row.original.id}`}>
          <Search className="h-4 w-4" />
        </NavLink>
      ),
      accessorFn: (row) => row.id,
      footer: (props) => props.column.id,
    },
  ]
}
