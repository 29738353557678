import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

export function getDateString(timestamp: string): string {
  if (timestamp === null || timestamp === "" || timestamp === undefined) {
    return "no time"
  }

  const parsedTimestamp = Date.parse(timestamp.replace("CEST", ""))
  if (!Number.isNaN(parsedTimestamp)) {
    const date = new Date(parsedTimestamp)
    return date.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  }
  return "invalid"
}

export function calculatePageCount(total: number, pageSize: number): number {
  if (total > 0 && pageSize > 0) {
    return total != null && pageSize != null ? Math.ceil(total / pageSize) : -1
  }

  return 0
}
