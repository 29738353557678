import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import createColumns from "./columns"
import { useListIdentityLinksQuery } from "@/services/auth/identity-link"

function LinkTable(): ReactElement {
  const { data } = useListIdentityLinksQuery({})

  return data != null && data.identityLinks != null ? (
    <div className="mx-auto py-4">
      <DataTable
        enableToolbar={false}
        columns={createColumns()}
        data={data.identityLinks}
      />
    </div>
  ) : (
    <div />
  )
}

export default LinkTable
