import { ColumnDef } from "@tanstack/react-table"
import { FallDetectionSensor } from "@/services/sensors/fall-detection-sensor"

export default function createColumns(): ColumnDef<FallDetectionSensor>[] {
  return [
    {
      accessorKey: "description",
      header: "Beschreibung",
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
  ]
}
