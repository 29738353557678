import { ColumnDef } from "@tanstack/react-table"
import { OrgAssignment } from "@/services/auth/org-assignment"

export default function createColumns(): ColumnDef<OrgAssignment>[] {
  return [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "userId",
      header: "Nutzer Id",
    },
    {
      accessorKey: "orgId",
      header: "Organisations Id",
    },
    {
      accessorKey: "role",
      header: "Rolle",
    },
  ]
}
