import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import React, { ReactElement } from "react"
import { useListSensorClustersQuery } from "@/services/sensor-cluster/sensor-cluster"
import CreateClusterForm from "@/features/sensorcluster/forms/create-cluster-form"
import createColumns from "@/features/sensorcluster/tables/admin-table/colums"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"

function DevicesAdminPage(): ReactElement {
  const { orgId } = useParams()
  const { data } = useListSensorClustersQuery({ orgId })

  return (
    <div className="flex-col w-full">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">
          Sensorverbund - Konfiguration
        </h2>
      </div>
      <Tabs defaultValue="overview" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="overview">Übersicht</TabsTrigger>
          </TabsList>
        </div>
        <div className="border-b" />
        <TabsContent value="overview">
          <CreateClusterForm />
          {data != null && data.sensorClusters != null && (
            <div className="mx-auto py-4">
              <DataTable columns={createColumns()} data={data.sensorClusters} />
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default DevicesAdminPage
