import { backendApi } from "../api"

const gpsSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putGpsSensor: build.mutation<GpsSensor, PutGpsSensorRequest>({
      query: (request) => ({
        url: `sensor/gps`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["GPS"],
    }),
    listGpsSensorsForCluster: build.query<
      GpsSensorList,
      ListGpsSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/gps/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["GPS"],
    }),
  }),
})

export const { usePutGpsSensorMutation, useListGpsSensorsForClusterQuery } =
  gpsSensorApi

export interface GpsSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  longitude: number
  latitude: number
  lastSignal?: string
  modelNumber?: string
}

export interface GpsSensorList {
  gpsSensors: GpsSensor[]
}

export interface PutGpsSensorRequest {
  gpsSensor: GpsSensor
}

export interface ListGpsSensorsForClusterRequest {
  clusterId: string | undefined
}
