import { backendApi } from "../api"

const carbonDioxideSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putCarbonDioxideSensor: build.mutation<
      CarbonDioxideSensor,
      PutCarbonDioxideSensorRequest
    >({
      query: (request) => ({
        url: `sensor/carbondioxide`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["CarbonDioxide"],
    }),
    listCarbonDioxideSensorsForCluster: build.query<
      CarbonDioxideSensorList,
      ListCarbonDioxideSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/carbondioxide/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["CarbonDioxide"],
    }),
  }),
})

export const {
  usePutCarbonDioxideSensorMutation,
  useListCarbonDioxideSensorsForClusterQuery,
} = carbonDioxideSensorApi

export interface CarbonDioxideSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  carbonDioxide: number
  minLimit: number
  maxLimit: number
  lastSignal?: string
  modelNumber?: string
}

export interface CarbonDioxideSensorList {
  carbonDioxideSensors: CarbonDioxideSensor[]
}

export interface PutCarbonDioxideSensorRequest {
  carbonDioxideSensor: CarbonDioxideSensor
}

export interface ListCarbonDioxideSensorsForClusterRequest {
  clusterId: string | undefined
}
