import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import { Search } from "lucide-react"
import { NavLink } from "react-router-dom"
import { HumiditySensor } from "@/services/sensors/humidity-sensor"

export default function createColumns(): ColumnDef<HumiditySensor>[] {
  return [
    {
      accessorKey: "description",
      header: "Beschreibung",
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },

    {
      accessorKey: "humidity",
      header: "Luftfeuchtigkeit",
    },
    {
      accessorKey: "minLimit",
      header: "Mindestfeuchtigkeit",
    },
    {
      accessorKey: "maxLimit",
      header: "Maximalfeuchtigkeit",
    },
    {
      accessorKey: "id",
      header: "Details",
      cell: (info) => (
        <NavLink to={`/device-admin/${info.row.original.id}`}>
          <Search className="h-4 w-4" />
        </NavLink>
      ),
      accessorFn: (row) => row.id,
      footer: (props) => props.column.id,
    },
  ]
}
