import React, { ReactElement } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { CarbonDioxideSensor } from "@/services/sensors/carbon-dioxide-sensor"

interface CarbonDioxideSensorSelectionProps {
  selectedId: string
  selectSensor: (sensorId: string) => void
  data: CarbonDioxideSensor[]
}

function CarbonDioxideSensorSelection({
  selectedId,
  selectSensor,
  data,
}: CarbonDioxideSensorSelectionProps): ReactElement {
  return (
    <Select
      defaultValue={selectedId}
      onValueChange={(value) => selectSensor(value)}
    >
      <SelectTrigger>
        <SelectValue placeholder="Kohlenstoffdioxid" />
      </SelectTrigger>
      <SelectContent>
        {data.map((carbonDioxideSensor) => (
          <SelectItem
            key={carbonDioxideSensor.id}
            value={carbonDioxideSensor.id}
          >
            {carbonDioxideSensor.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default CarbonDioxideSensorSelection
