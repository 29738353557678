import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { getDateString } from "@/lib/utils"
import { TemperatureSensor } from "@/services/sensors/temperature-sensor"
import React, { ReactElement } from "react"

interface TemperatureDashboardCardProps {
  tempSensor: TemperatureSensor
}

function TemperatureDashboardCard({
  tempSensor,
}: TemperatureDashboardCardProps): ReactElement {
  return (
    <Card className="h-55 bg-white dark:bg-gray-900 rounded-lg">
      <CardHeader>
        <CardTitle>{tempSensor.description}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col items-start justify-start gap-2">
        <div className="text-4xl font-bold text-left">
          {tempSensor.temperature}
          <span className="text-4xl font-medium">°C</span>
        </div>
        <p className="text-gray-500 dark:text-gray-400 text-sm text-left">
          Letztes Signal:{" "}
          {tempSensor.lastSignal
            ? getDateString(tempSensor.lastSignal)
            : "Ungültiger Zeitstempel"}
        </p>
        {/*<div className="w-full h-4 bg-gray-200 dark:bg-gray-800 rounded-full overflow-hidden">
          <div className="h-full bg-gradient-to-r from-blue-500 to-green-500 w-[75%]" />
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="text-gray-500 dark:text-gray-400 text-sm">
            Min: {tempSensor.minLimit}°C
          </div>
          <div className="text-gray-500 dark:text-gray-400 text-sm">
            Max: {tempSensor.maxLimit}°C
          </div>
        </div>*/}
      </CardContent>
    </Card>
  )
}

export default TemperatureDashboardCard
