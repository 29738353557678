import React, { ReactElement } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { HumiditySensor } from "@/services/sensors/humidity-sensor"

interface HumiditySensorSelectionProps {
  selectedId: string
  selectSensor: (sensorId: string) => void
  data: HumiditySensor[]
}

function HumiditySensorSelection({
  selectedId,
  selectSensor,
  data,
}: HumiditySensorSelectionProps): ReactElement {
  return (
    <Select
      defaultValue={selectedId}
      onValueChange={(value) => selectSensor(value)}
    >
      <SelectTrigger>
        <SelectValue placeholder="Humiditysensor" />
      </SelectTrigger>
      <SelectContent>
        {data.map((humiditySensor) => (
          <SelectItem key={humiditySensor.id} value={humiditySensor.id}>
            {humiditySensor.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default HumiditySensorSelection
