import React, { ReactElement } from "react"
import { Template } from "@/services/templates/templates"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"
import TemplateCardSensorRow from "@/features/templates/components/template-card-sensor-row"
import lht65 from "./lht65.jpg"
import CreateDeviceFromTemplateForm from "@/features/templates/forms/create-device-from-template-form"

interface TemplateCardProps {
  template: Template
}

function TemplateCard({ template }: TemplateCardProps): ReactElement {
  return (
    <Card>
      <CardHeader className="flex items-center justify-center">
        <img className="h-52 w-52" src={lht65} alt={lht65} />
        <CardTitle className="flex flex-row items-center">
          <div className="flex flex-col items-center">
            {template.description}
            <span className="text-sm font-light">
              Hersteller: {template.manufacturer}
            </span>
            <span className="text-sm font-light">
              Modell: {template.modelNumber}
            </span>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="p-2 h-48">
          {template.sensors != null && template.sensors.length > 0 && (
            <div>
              {template.sensors.map((sensor) => (
                <TemplateCardSensorRow sensor={sensor} />
              ))}
            </div>
          )}
        </ScrollArea>
        <div className="h-1 border-b mt-4 mb-4" />
        <CreateDeviceFromTemplateForm templateId={template.id} />
      </CardContent>
    </Card>
  )
}

export default TemplateCard
