import { backendApi } from "../api"

const temperatureSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putTemperatureSensor: build.mutation<
      TemperatureSensor,
      PutTemperatureSensorRequest
    >({
      query: (request) => ({
        url: `sensor/temperature`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Temperature"],
    }),
    listTemperatureSensorsForCluster: build.query<
      TemperatureSensorList,
      ListTemperatureSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/temperature/list/cluster`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Temperature"],
    }),
    listTemperatureSensors: build.query<
      TemperatureSensorList,
      ListTemperatureSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/temperature/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Temperature"],
    }),
  }),
})

export const {
  usePutTemperatureSensorMutation,
  useListTemperatureSensorsForClusterQuery,
  useListTemperatureSensorsQuery,
} = temperatureSensorApi

export interface TemperatureSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  temperature: number
  minLimit: number
  maxLimit: number
  lastSignal?: string
  modelNumber?: string
}

export interface TemperatureSensorList {
  temperatureSensors: TemperatureSensor[]
}

export interface PutTemperatureSensorRequest {
  temperatureSensor: TemperatureSensor
}

export interface ListTemperatureSensorsForClusterRequest {
  clusterId?: string
}
