import React, { ReactElement } from "react"
import { Badge } from "@/components/ui/badge"
import { getDateString } from "@/lib/utils"

interface OpeningOverviewRowProps {
  description: string
  opening: boolean
  lastSignal?: string
}

function OpeningOverviewRow({
  description,
  opening,
  lastSignal,
}: OpeningOverviewRowProps): ReactElement {
  return (
    <div className="rounded-lg border p-4 m-2 h-16 flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h2 className="font-bold">{description}</h2>
        <p className="text-sm text-muted-foreground">
          Letzte Nachricht: {getDateString(lastSignal!)}
        </p>
      </div>
      <Badge variant={!opening ? "default" : "destructive"} className="h-8">
        {opening ? "Offen" : "Geschlossen"}
      </Badge>
    </div>
  )
}

export default OpeningOverviewRow
