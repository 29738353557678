import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useEffect, useState } from "react"
import { useListDistanceSensorsForClusterQuery } from "@/services/sensors/distance-sensor"
import { Card } from "@/components/ui/card"
import DistanceBarChart from "@/features/sensors/distance/components/distance-bar-chart"
import DistanceHistoryTablePage from "../tables/history/distance-history-table"
import DistanceSensorSelection from "../components/distance-sensor-selection"

interface DistanceOverviewPageProps {
  clusterId: string
}

function DistanceOverviewPage({
  clusterId,
}: DistanceOverviewPageProps): ReactElement {
  const [distanceSensorId, setDistanceSensorId] = useState("")
  const { data: distanceData } = useListDistanceSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  useEffect(() => {
    if (
      distanceData != null &&
      distanceData.distanceSensors != null &&
      distanceData.distanceSensors.length > 0
    ) {
      setDistanceSensorId(distanceData.distanceSensors[0].id)
    }
  }, [distanceData])

  const getThreshold = (): number => {
    const sensor = distanceData?.distanceSensors.find(
      (element) => element.id === distanceSensorId,
    )

    if (sensor != null) {
      return sensor.threshold
    }

    return 0
  }

  return (
    <div className="flex flex-col w-full">
      {distanceData != null &&
        distanceData.distanceSensors != null &&
        distanceData.distanceSensors.length > 0 && (
          <DistanceSensorSelection
            data={distanceData.distanceSensors}
            selectedId={distanceSensorId}
            selectSensor={(value: string) => setDistanceSensorId(value)}
          />
        )}
      {distanceSensorId != null && (
        <Card className="p-4 my-2">
          <DistanceBarChart
            sensorId={distanceSensorId}
            threshold={getThreshold()}
          />
        </Card>
      )}
      {distanceSensorId != null && (
        <DistanceHistoryTablePage sensorId={distanceSensorId} />
      )}
    </div>
  )
}

export default DistanceOverviewPage
