import React, { ReactElement } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { FallDetectionSensor } from "@/services/sensors/fall-detection-sensor"

interface FallDetectionSensorSelectionProps {
  selectedId: string
  selectSensor: (sensorId: string) => void
  data: FallDetectionSensor[]
}

function FallDetectionSensorSelection({
  selectedId,
  selectSensor,
  data,
}: FallDetectionSensorSelectionProps): ReactElement {
  if (selectedId == null) {
    return <div />
  }

  return (
    <Select
      defaultValue={selectedId}
      onValueChange={(value) => selectSensor(value)}
    >
      <SelectTrigger>
        <SelectValue placeholder="Sturzsensor" />
      </SelectTrigger>
      <SelectContent>
        {data.map((fallDetectionSensor) => (
          <SelectItem
            key={fallDetectionSensor.id}
            value={fallDetectionSensor.id}
          >
            {fallDetectionSensor.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default FallDetectionSensorSelection
