import { ColumnDef } from "@tanstack/react-table"
import DateTableLabel from "@/components/date-table-label"
import { CarbonDioxideHistoryEntry } from "@/services/sensors/carbon-dioxide-history"

export default function createColumns(): ColumnDef<CarbonDioxideHistoryEntry>[] {
  return [
    {
      accessorKey: "humidity",
      header: "Luftfeuchtigkeit",
      cell: (info) => `${info.row.original.carbonDioxide} ppm`,
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "messageTimestamp",
      header: "Zeitpunkt",
      cell: (info) =>
        DateTableLabel({
          timestamp: info.getValue<string>(),
        }),
      accessorFn: (row) => row.messageTimestamp,
    },
  ]
}
