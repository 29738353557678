import React, { ReactElement } from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useParams } from "react-router-dom"
import FallDetectionNotificationsTablePage from "@/features/notifications/falldetection/tables/notifications/fall-detection-notifications-table-page"

function NotificationsOverviewPage(): ReactElement {
  const { orgId } = useParams()

  return (
    <div className="flex-col w-full overflow-y-auto">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">
          Benachrichtigungen
        </h2>
      </div>
      <Tabs defaultValue="fallsensor" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="fallsensor">Sturzsensoren</TabsTrigger>
          </TabsList>
        </div>
        <div className="border-b" />
        <TabsContent value="fallsensor">
          {orgId != null && (
            <FallDetectionNotificationsTablePage orgId={orgId} />
          )}
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default NotificationsOverviewPage
