import React, { ReactElement, useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetUserInfoQuery } from "@/services/users/users"
import { useListCustomersQuery } from "@/services/customers/customers"

function RootGroupWrapper(): ReactElement {
  const { accounts } = useMsal()
  const navigate = useNavigate()
  const { groupId } = useParams()
  const { data, isSuccess, isFetching, isLoading } = useGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { identityProviderId: accounts[0].localAccountId }
      : skipToken,
  )

  const { data: orgData, isSuccess: groupSuccess } = useListCustomersQuery({})

  useEffect(() => {
    if (
      isSuccess &&
      groupSuccess &&
      data != null &&
      (groupId == null || groupId === "")
    ) {
      if (
        data.isGlobalAdmin &&
        orgData != null &&
        orgData.organisations != null
      ) {
        navigate(
          orgData.organisations.length > 0
            ? `/${orgData.organisations[0].id}/dashboard`
            : "/",
        )
        return
      }

      if (
        orgData != null &&
        orgData.organisations != null &&
        data.orgAssignment.length > 0
      ) {
        navigate(`${data.orgAssignment[0].orgId}/dashboard`)
        return
      }

      navigate("/")
    }
  }, [data, orgData, groupId, navigate])

  if (isLoading || isFetching) {
    return (
      <div className="bg-gray-100 h-full min-h-screen flex items-center justify-center">
        <div className="h-20">Bitte warten</div>
      </div>
    )
  }

  return <Outlet />
}

export default RootGroupWrapper
