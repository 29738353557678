import { backendApi } from "../api"

const fallDetectionSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putFallDetectionSensor: build.mutation<
      FallDetectionSensor,
      PutFallDetectionSensorRequest
    >({
      query: (request) => ({
        url: `sensor/falldetection`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["FallDetection"],
    }),
    listFallDetectionSensorsForCluster: build.query<
      FallDetectionSensorList,
      ListFallDetectionSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/falldetection/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["FallDetection"],
    }),
    listFallDetectionSensorsByCustomer: build.query<
      FallDetectionSensorList,
      ListFallDetectionSensorsByCustomerRequest
    >({
      query: (request) => ({
        url: `sensor/falldetection/listbycustomer`,
        method: "GET",
        params: request,
      }),
      providesTags: ["FallDetection"],
    }),
  }),
})

export const {
  usePutFallDetectionSensorMutation,
  useListFallDetectionSensorsForClusterQuery,
  useListFallDetectionSensorsByCustomerQuery,
} = fallDetectionSensorApi

export interface FallDetectionSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  gaddIndex: number
  lastSignal?: string
  modelNumber?: string
}

export interface FallDetectionSensorList {
  fallDetectionSensors: FallDetectionSensor[]
}

export interface PutFallDetectionSensorRequest {
  fallDetectionSensor: FallDetectionSensor
}

export interface ListFallDetectionSensorsForClusterRequest {
  clusterId: string | undefined
}

export interface ListFallDetectionSensorsByCustomerRequest {
  orgId: string | undefined
}
