import React, { ReactElement } from "react"
import OrgAssignmentTable from "@/features/organisation/tables/org-assignment/org-assignment-table"
import CreateOrgAssignmentForm from "@/features/organisation/forms/create-org-assignment-form"

function OrgAssignmentPage(): ReactElement {
  return (
    <div className="flex-col w-full">
      <CreateOrgAssignmentForm />
      <div className="mt-4 border-b" />
      <OrgAssignmentTable />
    </div>
  )
}
export default OrgAssignmentPage
