import { backendApi } from "../api"

const workflowsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    createWorkflow: build.mutation<Workflow, CreateFlowRequest>({
      query: (request) => ({
        url: `/workflows/workflow`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Workflows"],
    }),
    listWorkflows: build.query<ListResponse, ListFlowsRequest>({
      query: (request) => ({
        url: `/workflows/workflow/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Workflows"],
    }),
  }),
})

export const { useCreateWorkflowMutation, useListWorkflowsQuery } = workflowsApi

export interface ListResponse {
  workflow: Workflow[]
}

export interface Workflow {
  id: string
  description: string
  singleton: boolean
  creator: string
  isActive: boolean
  activities: any[]
}

export interface CreateFlowRequest {
  workflow: Workflow
}

export interface ListFlowsRequest {
  creatorId: string | undefined
}
