import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import DateTableLabel from "@/components/date-table-label"
import { Badge } from "@/components/ui/badge"
import { SwitchHistoryEntry } from "@/services/sensors/switch-history"

export default function createColumns(): ColumnDef<SwitchHistoryEntry>[] {
  return [
    {
      accessorKey: "switchState",
      header: "Schalterstatus",
      cell: (info) => (
        <Badge
          variant={!info.row.original.switchState ? "default" : "destructive"}
          className="h-8 w-24 items-center justify-center"
        >
          {info.row.original.switchState ? "An" : "Aus"}
        </Badge>
      ),
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "messageTimestamp",
      header: "Zeitpunkt",
      cell: (info) =>
        DateTableLabel({
          timestamp: info.getValue<string>(),
        }),
      accessorFn: (row) => row.messageTimestamp,
    },
  ]
}
