import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import createColumns from "@/features/sensors/humidity/tables/admin-table/columns"
import { useListHumiditySensorsForClusterQuery } from "@/services/sensors/humidity-sensor"

function HumidityAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data } = useListHumiditySensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  return data != null && data.humiditySensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.humiditySensors} />
    </div>
  ) : (
    <div />
  )
}

export default HumidityAdminTablePage
