import { useListOpeningSensorsForClusterQuery } from "@/services/sensors/opening-sensor"
import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useEffect, useState } from "react"
import OpeningSensorSelection from "../components/opening-sensor-selection"
import OpeningHistoryTablePage from "../tables/history/opening-history-table-page"

interface OpeningOverviewPageProps {
  clusterId: string
}

function OpeningOverviewPage({
  clusterId,
}: OpeningOverviewPageProps): ReactElement {
  const [openingSensorId, setOpeningSensorId] = useState("")
  const { data: openingData } = useListOpeningSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  useEffect(() => {
    if (
      openingData != null &&
      openingData.openingSensors != null &&
      openingData.openingSensors.length > 0
    ) {
      setOpeningSensorId(openingData.openingSensors[0].id)
    }
  }, [openingData])

  return (
    <div>
      {openingData != null &&
        openingData.openingSensors != null &&
        openingData.openingSensors.length > 0 && (
          <OpeningSensorSelection
            data={openingData.openingSensors}
            selectedId={openingSensorId}
            selectSensor={(value: string) => setOpeningSensorId(value)}
          />
        )}
      {openingSensorId != null && (
        <OpeningHistoryTablePage sensorId={openingSensorId} />
      )}
    </div>
  )
}

export default OpeningOverviewPage
