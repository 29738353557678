import { backendApi } from "../api"

const customerApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putCustomer: build.mutation<Customer, PutCustomerRequest>({
      query: (request) => ({
        url: `organisations`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Organisations"],
    }),
    listCustomers: build.query<List, ListCustomersRequest>({
      query: (request) => ({
        url: `organisations/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Organisations"],
    }),
    showCustomer: build.query<Customer, ShowCustomerRequest>({
      query: (request) => ({
        url: `organisations`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Organisations"],
    }),
  }),
})

export const {
  usePutCustomerMutation,
  useListCustomersQuery,
  useShowCustomerQuery,
} = customerApi

export interface Customer {
  id: string
  description: string
  portalUrl: string
}

export interface List {
  organisations: Customer[]
}

export interface PutCustomerRequest {
  id: string
  description: string
  portalUrl: string
}

export interface ListCustomersRequest {}

export interface ShowCustomerRequest {
  customerId: string
}
