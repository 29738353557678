import React, { ReactElement, useState } from "react"
import { skipToken } from "@reduxjs/toolkit/query"
import { PaginationState } from "@tanstack/react-table"
import { PagedDataTable } from "@/components/paged-data-table"
import { calculatePageCount } from "@/lib/utils"
import { useListGpsHistoryForSensorQuery } from "@/services/sensors/gps-history"
import createColumns from "./columns"

interface GpsHistoryTablePageProps {
  sensorId: string
}

function GpsHistoryTablePage({
  sensorId,
}: GpsHistoryTablePageProps): ReactElement {
  const [paginationState, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const { data } = useListGpsHistoryForSensorQuery(
    sensorId != null && sensorId !== ""
      ? {
          sensorId,
          limit: paginationState.pageSize,
          page: paginationState.pageIndex,
        }
      : skipToken,
  )

  return data != null && data.data != null ? (
    <div className="mx-auto py-4 w-full">
      <PagedDataTable
        columns={createColumns()}
        data={data.data}
        paginationOpts={{
          pageCount: calculatePageCount(data.total, paginationState.pageSize),
          enabled: true,
          paginationState,
          paginationSetter: setPagination,
        }}
      />
    </div>
  ) : (
    <div />
  )
}

export default GpsHistoryTablePage
