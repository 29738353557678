import React, { ReactElement } from "react"

import { skipToken } from "@reduxjs/toolkit/query"
import { useListDistanceHistoryForSensorQuery } from "@/services/sensors/distance-history"
import {
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"

interface DistanceBarChartProps {
  sensorId: string
  threshold: number
}

function DistanceBarChart({
  sensorId,
  threshold,
}: DistanceBarChartProps): ReactElement {
  const { data: historyData, isLoading } = useListDistanceHistoryForSensorQuery(
    sensorId != null ? { sensorId } : skipToken,
  )

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart width={150} height={40} data={historyData?.data}>
        <Bar dataKey="distance" fill="#8884d8" />
        <ReferenceLine y={threshold} stroke="#f54242" strokeWidth={4} />
        <YAxis />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default DistanceBarChart
