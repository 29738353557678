/**
 * v0 by Vercel.
 * @see https://v0.dev/t/XepaLGHMk0N
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { CardTitle, CardHeader, CardContent, Card } from "@/components/ui/card"
import { getDateString } from "@/lib/utils"
import { GpsSensor } from "@/services/sensors/gps-sensor"
import {
  useGetFallCountForClusterQuery,
  useListFallDetectionNotificationsForCustomerQuery,
} from "@/services/sensors/fall-detection-history"
import { skipToken } from "@reduxjs/toolkit/query"
import { useListSensorClustersQuery } from "@/services/sensor-cluster/sensor-cluster"
import { useListFallDetectionSensorsByCustomerQuery } from "@/services/sensors/fall-detection-sensor"

interface FallSensorDashboardCardProps {
  orgId?: string
  clusterId?: string
}

export default function FallSensorDashboardCard({
  orgId,
  clusterId,
}: FallSensorDashboardCardProps) {
  const { data: count } = useGetFallCountForClusterQuery(
    orgId != null && orgId !== "" && clusterId != null && clusterId !== ""
      ? {
          orgId,
          clusterId,
        }
      : skipToken,
  )

  return (
    <Card className="h-55">
      <CardHeader>
        <CardTitle>Heutige Stürze</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <div className="text-4xl font-bold">{count} Stürze</div>
        </div>
      </CardContent>
    </Card>
  )
}
