import React, { ReactElement } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useListCarbonDioxideSensorsForClusterQuery } from "@/services/sensors/carbon-dioxide-sensor"
import createColumns from "./columns"

function CarbonDioxideAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data } = useListCarbonDioxideSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  return data != null && data.carbonDioxideSensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.carbonDioxideSensors} />
    </div>
  ) : (
    <div />
  )
}

export default CarbonDioxideAdminTablePage
