import { backendApi } from "../api"

const switchHistoryApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    listSwitchHistoryForSensor: build.query<
      ListResponse,
      ListSwitchHistoryForSensorRequest
    >({
      query: (request) => ({
        url: `sensor/switch/history/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["SwitchHistory"],
    }),
  }),
})

export const { useListSwitchHistoryForSensorQuery } = switchHistoryApi

export interface SwitchHistoryEntry {
  id: string
  sensorId: string
  clusterId: string
  orgId: string
  serialNumber: string
  switchState: number
  messageTimestamp: string
}

export interface ListResponse {
  total: number
  limit: number
  page: number
  data: SwitchHistoryEntry[]
}

export interface ListSwitchHistoryForSensorRequest {
  sensorId: string | undefined
  limit?: number
  page?: number
}
