import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import { Flow } from "@/services/flows/flows"
import { GearIcon, LightningBoltIcon } from "@radix-ui/react-icons"
import { Button } from "@/components/ui/button"
import { NavigateFunction } from "react-router-dom"
import { Workflow } from "@/services/workflows/workflows"

interface columnProps {
  nav: NavigateFunction
}

export default function createColumns({
  nav,
}: columnProps): ColumnDef<Workflow>[] {
  return [
    {
      accessorKey: "icon",
      header: "Icon",
      cell: (info) => <LightningBoltIcon />,
    },
    {
      accessorKey: "description",
      header: "Beschreibung",
      cell: (info) => `${info.row.original.description}`,
    },
    {
      accessorKey: "creator",
      header: "Ersteller",
      cell: (info) => `${info.row.original.creator}`,
    },
    {
      id: "actions",
      header: "Optionen",
      cell: ({ row }) => (
        <Button
          variant="outline"
          onClick={() => nav(`${row.original.id}/actions`)}
        >
          <GearIcon />
        </Button>
      ),
    },
  ]
}
