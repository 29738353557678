import React, { ReactElement } from "react"
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import "./App.css"
import DevicesPage from "@/pages/devices/devices-page"
import DevicesAdminPage from "@/pages/devices/device-admin-page"
import DeviceConfigPage from "@/pages/devices/device-config-page"
import DeviceOverviewPage from "@/pages/devices/device-overview-page"
import TemplatesPage from "@/pages/templates/templates-page"
import FlowsPage from "@/pages/flows/flows-page"
import ActionPage from "@/pages/flows/action-page"
import { AppShell } from "@/app-shell"
import NotificationsOverviewPage from "@/pages/notifications/notifications-overview-page"
import GatewaysPage from "./pages/gateways/gateways-page"
import DashboardV2 from "./pages/dashboard/dashboard-v2"
import SettingsLayout from "./features/organisation/pages/organisation-settings"
import IntegrationPage from "./features/organisation/pages/integration-page"
import OverviewPage from "./features/organisation/pages/overview-page"
import UsersPage from "@/features/organisation/pages/user-page"
import IdentityLinkPage from "@/features/identity-link/pages/identity-link-page"
import OrgAssignmentPage from "@/features/organisation/pages/org-assignment-page"
import RootGroupWrapper from "@/services/auth/RootGroupWrapper"

function App(): ReactElement {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootGroupWrapper />}>
        <Route path=":orgId" element={<AppShell />}>
          <Route path="dashboard" index element={<DashboardV2 />} />
          <Route path="templates" element={<TemplatesPage />} />
          <Route path="notifications" element={<NotificationsOverviewPage />} />
          <Route
            path="notifications/:clusterId"
            element={<NotificationsOverviewPage />}
          />
          <Route path="devices" element={<DevicesPage />} />
          <Route path="devices/:clusterId" element={<DeviceOverviewPage />} />
          <Route path="device-admin" element={<DevicesAdminPage />} />
          <Route
            path="device-admin/:clusterId"
            element={<DeviceConfigPage />}
          />
          <Route path="gateways" element={<GatewaysPage />} />
          <Route path="flows" element={<FlowsPage />} />
          <Route path="flows/:flowId/actions" element={<ActionPage />} />
          <Route path="organisations" element={<SettingsLayout />}>
            <Route path="users" element={<UsersPage />} />
            <Route path="overview" element={<OverviewPage />} />
            <Route path="integrations" element={<IntegrationPage />} />
            <Route path="identitylink" element={<IdentityLinkPage />} />
            <Route path="orgassignment" element={<OrgAssignmentPage />} />
          </Route>
        </Route>
      </Route>,
    ),
  )

  return <RouterProvider router={router} />
}

export default App
