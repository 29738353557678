import { backendApi } from "../api"

const openingSensorApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putOpeningSensor: build.mutation<OpeningSensor, PutOpeningSensorRequest>({
      query: (request) => ({
        url: `sensor/opening`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Opening"],
    }),
    listOpeningSensorsForCluster: build.query<
      OpeningSensorList,
      ListOpeningSensorsForClusterRequest
    >({
      query: (request) => ({
        url: `sensor/opening/list`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Opening"],
    }),
  }),
})

export const {
  usePutOpeningSensorMutation,
  useListOpeningSensorsForClusterQuery,
} = openingSensorApi

export interface OpeningSensor {
  id: string
  clusterId: string
  orgId: string
  description: string
  serialNumber: string
  sensorIdentifier: string
  openingStatus: boolean
  maxOpeningDuration: number
  lastSignal?: string
  modelNumber?: string
}

export interface OpeningSensorList {
  openingSensors: OpeningSensor[]
}

export interface PutOpeningSensorRequest {
  openingSensor: OpeningSensor
}

export interface ListOpeningSensorsForClusterRequest {
  clusterId: string | undefined
}
