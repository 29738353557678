import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useListCustomersQuery } from "@/services/customers/customers"
import { backendApi } from "@/services/api"

interface OrgComboboxProps {
  selectedOrg?: string
}

export function OrgComboBox({ selectedOrg }: OrgComboboxProps) {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data } = useListCustomersQuery({})

  useEffect(() => {
    if (selectedOrg != null) {
      dispatch(backendApi.util.invalidateTags(["SensorCluster"]))
    }
  }, [dispatch, selectedOrg])

  useEffect(() => {
    if (data != null && data.organisations != null && selectedOrg != null) {
      const org = data.organisations.find(
        (organisation) => organisation.id === selectedOrg,
      )

      if (org != null) {
        setValue(`${org.id}-${org.description}`)
      }
    }
  }, [data, selectedOrg, value])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between hover:bg-primary hover:text-white"
        >
          <p className="text-ellipsis overflow-hidden">
            {value
              ? data?.organisations?.find(
                  (org) => `${org.id}-${org.description}` === value,
                )?.description
              : "Organisation auswählen"}
          </p>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={"Organisation suchen"} />
          <CommandList>
            <CommandEmpty>{"Keine Organisation vorhanden"}</CommandEmpty>
            <CommandGroup>
              {data?.organisations?.map((org) => (
                <CommandItem
                  className="aria-selected:bg-primary aria-selected:text-white"
                  key={org.id}
                  value={`${org.id}-${org.description}`}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue)
                    setOpen(false)
                    if (org.id != null) {
                      navigate(`/${org.id}/dashboard`)
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4 hover:text-white",
                      value === `${org.id}-${org.description}`
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />

                  {org.description}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
