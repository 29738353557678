import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import React, { ReactElement } from "react"
import CreateTemperatureForm from "@/features/sensors/temperature/forms/create-temperature-form"
import { useParams } from "react-router-dom"
import TemperatureAdminTablePage from "@/features/sensors/temperature/tables/admin-table/temperature-admin-table-page"
import CreateHumidityForm from "@/features/sensors/humidity/forms/create-humidity-form"
import HumidityAdminTablePage from "@/features/sensors/humidity/tables/admin-table/humidity-admin-table-page"
import CreateCarbonDioxideForm from "@/features/sensors/carbondioxide/forms/create-carbon-dioxide-form"
import CarbonDioxideAdminTablePage from "@/features/sensors/carbondioxide/tables/admin-table/carbondioxide-admin-table-page"
import CreateOpeningForm from "@/features/sensors/opening/forms/create-opening-form"
import OpeningAdminTablePage from "@/features/sensors/opening/tables/admin-table/opening-admin-table-page"
import CreateGpsForm from "@/features/sensors/gps/forms/create-gps-form"
import GpsAdminTablePage from "@/features/sensors/gps/tables/admin-table/gps-admin-page"
import SwitchAdminTablePage from "@/features/sensors/switch/tables/admin-table/switch-admin-table-page"
import CreateSwitchForm from "@/features/sensors/switch/forms/create-switch-form"
import CreateDistanceForm from "@/features/sensors/distance/forms/create-distance-form"
import DistanceAdminTablePage from "@/features/sensors/distance/tables/admin-table/distance-admin-table"
import CreateFallDetectionForm from "@/features/sensors/fallsensor/forms/create-fall-detection-form"
import FallDetectionAdminTablePage from "@/features/sensors/fallsensor/tables/admin-table/fall-detection-admin-page"

function DeviceConfigPage(): ReactElement {
  const { clusterId } = useParams()
  return (
    <div className="flex-col w-full">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">
          Sensorverbund - Konfiguration
        </h2>
      </div>
      <Tabs defaultValue="temperature" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="temperature">Temperatur</TabsTrigger>
            <TabsTrigger value="humidity">Luftfeuchtigkeit</TabsTrigger>
            <TabsTrigger value="carbondioxide">Kohlenstoffdioxid</TabsTrigger>
            <TabsTrigger value="opening">Öffnungssensoren</TabsTrigger>
            <TabsTrigger value="gps">GPS-Sensor</TabsTrigger>
            <TabsTrigger value="switch">Schalter</TabsTrigger>
            <TabsTrigger value="distance">Distanz</TabsTrigger>
            <TabsTrigger value="falldetection">Sturzsensoren</TabsTrigger>
          </TabsList>
        </div>
        <div className="border-b" />
        <TabsContent value="temperature">
          {clusterId != null && <CreateTemperatureForm clusterId={clusterId} />}
          <TemperatureAdminTablePage />
        </TabsContent>
        <TabsContent value="humidity">
          {clusterId != null && <CreateHumidityForm clusterId={clusterId} />}
          <HumidityAdminTablePage />
        </TabsContent>
        <TabsContent value="carbondioxide">
          {clusterId != null && (
            <CreateCarbonDioxideForm clusterId={clusterId} />
          )}
          <CarbonDioxideAdminTablePage />
        </TabsContent>
        <TabsContent value="opening">
          {clusterId != null && <CreateOpeningForm clusterId={clusterId} />}
          <OpeningAdminTablePage />
        </TabsContent>
        <TabsContent value="gps">
          {clusterId != null && <CreateGpsForm clusterId={clusterId} />}
          <GpsAdminTablePage />
        </TabsContent>
        <TabsContent value="switch">
          {clusterId != null && <CreateSwitchForm clusterId={clusterId} />}
          <SwitchAdminTablePage />
        </TabsContent>
        <TabsContent value="distance">
          {clusterId != null && <CreateDistanceForm clusterId={clusterId} />}
          <DistanceAdminTablePage />
        </TabsContent>
        <TabsContent value="falldetection">
          {clusterId != null && (
            <CreateFallDetectionForm clusterId={clusterId} />
          )}
          <FallDetectionAdminTablePage />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default DeviceConfigPage
