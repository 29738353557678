/**
 * v0 by Vercel.
 * @see https://v0.dev/t/XepaLGHMk0N
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  Card,
} from "@/components/ui/card"
import { TemperatureSensor } from "@/services/sensors/temperature-sensor"
import { CarbonDioxideSensor } from "@/services/sensors/carbon-dioxide-sensor"
import { getDateString } from "@/lib/utils"

interface CarbonDioxideDashboardCardProps {
  carbonDioxideSensor: CarbonDioxideSensor
}

export default function CarbonDioxideDashboardCard({
  carbonDioxideSensor,
}: CarbonDioxideDashboardCardProps) {
  return (
    <Card className="h-55">
      <CardHeader>
        <CardTitle>{carbonDioxideSensor.description}</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div className="flex flex-col">
          <div className="text-4xl font-bold">
            {carbonDioxideSensor.carbonDioxide} ppm
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
            Letztes Signal:{" "}
            {carbonDioxideSensor.lastSignal
              ? getDateString(carbonDioxideSensor.lastSignal)
              : "Ungültiger Zeitstempel"}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center gap-2">
            <TriangleAlertIcon className="w-5 h-5 text-yellow-500" />
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Warnlimit
              </div>
              <div className="font-medium">
                {carbonDioxideSensor.minLimit} ppm
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <CircleAlertIcon className="w-5 h-5 text-red-500" />
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Alarmlimit
              </div>
              <div className="font-medium">
                {carbonDioxideSensor.maxLimit} ppm
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

function CircleAlertIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" x2="12" y1="8" y2="12" />
      <line x1="12" x2="12.01" y1="16" y2="16" />
    </svg>
  )
}

function TriangleAlertIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>
  )
}
