import React, { ReactElement } from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import FlowsTablePage from "@/features/flows/tables/flows-table-page"
import { useParams } from "react-router-dom"
import CreateWorkflowForm from "@/features/flows/forms/create-workflow-form"

function FlowsPage(): ReactElement {
  const { orgId } = useParams()
  return (
    <div className="flex-col w-full">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">Flows</h2>
      </div>
      <Tabs defaultValue="overview" className="space-y-4">
        <div className="w-full flex flex-row justify justify-between items-center">
          <TabsList>
            <TabsTrigger value="overview">Übersicht</TabsTrigger>
            {/* <TabsTrigger value="jobs">Jobs</TabsTrigger> */}
          </TabsList>
          <CreateWorkflowForm />
        </div>
        <div className="border-b" />
        <TabsContent value="overview">
          {orgId != null && <FlowsTablePage orgId={orgId} />}
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default FlowsPage
