import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import DateTableLabel from "@/components/date-table-label"
import { Badge } from "@/components/ui/badge"
import { GpsHistoryEntry } from "@/services/sensors/gps-history"

export default function createColumns(): ColumnDef<GpsHistoryEntry>[] {
  return [
    {
      accessorKey: "latitude",
      header: "Latitude",
      cell: (info) => (
        <Badge className="h-8 w-24 items-center justify-center">
          {info.row.original.latitude}
        </Badge>
      ),
    },
    {
      accessorKey: "longitude",
      header: "Longitude",
      cell: (info) => (
        <Badge className="h-8 w-24 items-center justify-center">
          {info.row.original.longitude}
        </Badge>
      ),
    },
    {
      accessorKey: "serialNumber",
      header: "Seriennummer",
    },
    {
      accessorKey: "messageTimestamp",
      header: "Zeitpunkt",
      cell: (info) =>
        DateTableLabel({
          timestamp: info.getValue<string>(),
        }),
      accessorFn: (row) => row.messageTimestamp,
    },
  ]
}
