import React, { ReactElement, ReactNode } from "react"

interface PageContainerProps {
  title: string
  children: ReactNode
}

function PageContainer({ title, children }: PageContainerProps): ReactElement {
  return (
    <div className="flex-1 flex-col">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">{title}</h2>
      </div>
      {children}
    </div>
  )
}

export default PageContainer
