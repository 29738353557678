import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useEffect, useState } from "react"
import {
  SwitchSensor,
  useListSwitchSensorsForClusterQuery,
  useSendSensorDownlinkMutation,
} from "@/services/sensors/switch-sensor"
import { Power } from "lucide-react"
import { Switch } from "@/components/ui/switch"
import SwitchHistoryTablePage from "@/features/sensors/switch/tables/history/switch-history-table-page"
import SwitchSensorSelection from "../components/switch-sensor-selection"

interface SwitchOverviewPageProps {
  clusterId: string
}

function SwitchOverviewPage({
  clusterId,
}: SwitchOverviewPageProps): ReactElement {
  const [switchSensorId, setSwitchSensorId] = useState("")
  const { data: switchData } = useListSwitchSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  const [sendDownlink, { isSuccess, error, isError }] =
    useSendSensorDownlinkMutation()

  useEffect(() => {
    if (
      switchData != null &&
      switchData.switchSensors != null &&
      switchData.switchSensors.length > 0
    ) {
      setSwitchSensorId(switchData.switchSensors[0].id)
    }
  }, [switchData])

  const getCurrentSensor = (): SwitchSensor | undefined => {
    return switchData?.switchSensors.find(
      (sensor) => sensor.id === switchSensorId,
    )
  }

  return (
    <div>
      {switchData != null &&
        switchData.switchSensors != null &&
        switchData.switchSensors.length > 0 && (
          <SwitchSensorSelection
            data={switchData.switchSensors}
            selectedId={switchSensorId}
            selectSensor={(value: string) => setSwitchSensorId(value)}
          />
        )}
      {switchData != null &&
        switchData.switchSensors != null &&
        switchData.switchSensors.length > 0 &&
        switchSensorId !== "" && (
          <div className="mt-4 flex flex-row space-x-2">
            <h1 className="font-bold">Schalterzustand: </h1>
            <Switch
              checked={getCurrentSensor()?.switchState}
              disabled={getCurrentSensor()?.stateIsDirty}
              onClick={() => {
                const currentSensor = getCurrentSensor()
                sendDownlink({
                  id: currentSensor?.id,
                  switchState: !currentSensor?.switchState,
                })
              }}
            >
              <Power />
            </Switch>
          </div>
        )}
      {switchSensorId !== "" && switchSensorId != null && (
        <SwitchHistoryTablePage sensorId={switchSensorId} />
      )}
    </div>
  )
}

export default SwitchOverviewPage
