import React, { ReactElement } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { SwitchSensor } from "@/services/sensors/switch-sensor"

interface SwitchSensorSelectionProps {
  selectedId: string
  selectSensor: (sensorId: string) => void
  data: SwitchSensor[]
}

function SwitchSensorSelection({
  selectedId,
  selectSensor,
  data,
}: SwitchSensorSelectionProps): ReactElement {
  return (
    <Select
      defaultValue={selectedId}
      onValueChange={(value) => selectSensor(value)}
    >
      <SelectTrigger>
        <SelectValue placeholder="Öffungssensor" />
      </SelectTrigger>
      <SelectContent>
        {data.map((switchSensor) => (
          <SelectItem key={switchSensor.id} value={switchSensor.id}>
            {switchSensor.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default SwitchSensorSelection
