import React, { ReactElement, useEffect } from "react"
import { DataTable } from "@/components/data-table"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useListSwitchSensorsForClusterQuery } from "@/services/sensors/switch-sensor"
import createColumns from "./columns"

function SwitchAdminTablePage(): ReactElement {
  const { clusterId } = useParams()
  const { data } = useListSwitchSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )
  return data != null && data.switchSensors != null ? (
    <div className="mx-auto py-4">
      <DataTable columns={createColumns()} data={data.switchSensors} />
    </div>
  ) : (
    <div />
  )
}

export default SwitchAdminTablePage
