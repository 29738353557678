/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { useParams } from "react-router-dom"
import {
  useCreateWorkflowMutation,
  Workflow,
} from "@/services/workflows/workflows"

const formSchema = z.object({
  id: z.string(),
  description: z.string(),
  singleton: z.boolean(),
  creator: z.string(),
  isActive: z.boolean(),
})

function CreateWorkflowForm(): ReactElement {
  const { orgId } = useParams()
  const [open, setOpen] = useState(false)
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: uuidv4(),
      description: "Test",
      creator: uuidv4(),
      isActive: true,
      singleton: true,
    },
  })

  const [createWorkflow, { isSuccess }] = useCreateWorkflowMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    const request: Workflow = {
      id: uuidv4(),
      description: values.description,
      singleton: values.singleton,
      creator: orgId as string,
      isActive: values.isActive,
      activities: [],
    }

    createWorkflow({
      workflow: request,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Workflow erstellen</Button>
      </DialogTrigger>
      <DialogContent className="w-full">
        <DialogHeader>
          <DialogTitle>Neuen Workflow anlegen</DialogTitle>
          <DialogDescription>
            Legen Sie einen neuen Workflow an. Dieser wird ausgelöst, sobald die
            konfigurierten Bedinungen komplett erfüllt sind.
          </DialogDescription>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Beschreibung" {...field} />
                    </FormControl>
                    <FormDescription>Der Name für den Prozess</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isActive"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Flow ist aktiv</FormLabel>
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        {...form.register("isActive")}
                        id="isActive"
                        required
                      />
                      <div className="grid gap-1.5">
                        <Label
                          htmlFor="isActive"
                          className="text-sm font-medium"
                        >
                          Soll der Flow direkt aktiv sein?
                        </Label>
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="singleton"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Der Flow darf nur einmal parallel aktiv sein
                    </FormLabel>
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        {...form.register("singleton")}
                        id="singleton"
                        required
                      />
                      <div className="grid gap-1.5">
                        <Label
                          htmlFor="singleton"
                          className="text-sm font-medium"
                        >
                          Der Flow soll nur einmal gleichzeitig aktiv sein
                        </Label>
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateWorkflowForm
