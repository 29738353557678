/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { v4 as uuidv4 } from "uuid"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { usePutSwitchSensorMutation } from "@/services/sensors/switch-sensor"
import { useParams } from "react-router-dom"

const formSchema = z.object({
  clusterId: z.string(),
  description: z.string(),
  serialNumber: z.string(),
  sensorIdentifier: z.string(),
  modelNumber: z.string(),
  applicationId: z.string(),
  deviceId: z.string(),
})

interface CreateSwitchFormProps {
  clusterId: string
}

function CreateSwitchForm({ clusterId }: CreateSwitchFormProps): ReactElement {
  const [open, setOpen] = useState(false)
  const { orgId } = useParams()
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      clusterId,
      serialNumber: "",
      sensorIdentifier: "",
      applicationId: "",
      deviceId: "",
      modelNumber: "",
    },
  })

  const [createSwitchSensor, { isSuccess, error, isError }] =
    usePutSwitchSensorMutation()

  function onSubmit(values: z.infer<typeof formSchema>): void {
    createSwitchSensor({
      switchSensor: {
        id: uuidv4(),
        description: values.description,
        clusterId: values.clusterId,
        serialNumber: values.serialNumber,
        sensorIdentifier: values.sensorIdentifier,
        switchState: false,
        modelNumber: values.modelNumber,
        applicationId: values.applicationId,
        deviceId: values.deviceId,
        stateIsDirty: false,
        orgId: orgId as string,
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpen(false)
    }
  }, [isSuccess, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Schalter anlegen</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Neuen Schalter anlegen</DialogTitle>
          <DialogDescription>
            Legen Sie einen neuen Schalter für dieses Cluster an!
          </DialogDescription>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Beschreibung" {...field} />
                    </FormControl>
                    <FormDescription>
                      Der Name für das SensorCluster
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="serialNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input placeholder="Seriennummer" {...field} />
                    </FormControl>
                    <FormDescription>
                      Die Seriennummer des Sensors
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="sensorIdentifier"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sensor Identifier</FormLabel>
                    <FormControl>
                      <Input placeholder="Sensor" {...field} />
                    </FormControl>
                    <FormDescription>
                      Sensor Identifier des Sensors
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="applicationId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Application Id</FormLabel>
                    <FormControl>
                      <Input placeholder="Application Id" {...field} />
                    </FormControl>
                    <FormDescription>Application Id</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="deviceId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Device Id</FormLabel>
                    <FormControl>
                      <Input placeholder="Device Id" {...field} />
                    </FormControl>
                    <FormDescription>Device Id</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="modelNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Modellnummer</FormLabel>
                    <FormControl>
                      <Input placeholder="Modellnummer" {...field} />
                    </FormControl>
                    <FormDescription>Modellnummer</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateSwitchForm
