import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useEffect, useState } from "react"
import { Card } from "@/components/ui/card"
import { useListGpsSensorsForClusterQuery } from "@/services/sensors/gps-sensor"
import GpsSensorSelection from "../components/gps-sensor-selection"
import GpsMap from "../components/gps-map"
import GpsHistoryTablePage from "../tables/history/gps-history-table-page"

interface GpsOverviewPageProps {
  clusterId: string
}

function GpsOverviewPage({ clusterId }: GpsOverviewPageProps): ReactElement {
  const [gpsSensorId, setGpsSensorId] = useState("")
  const { data: gpsData } = useListGpsSensorsForClusterQuery(
    clusterId != null ? { clusterId } : skipToken,
  )

  useEffect(() => {
    if (
      gpsData != null &&
      gpsData.gpsSensors != null &&
      gpsData.gpsSensors.length > 0
    ) {
      setGpsSensorId(gpsData.gpsSensors[0].id)
    }
  }, [gpsData])

  return (
    <div className="flex flex-col w-full">
      {gpsData != null &&
        gpsData.gpsSensors != null &&
        gpsData.gpsSensors.length > 0 &&
        gpsSensorId != null &&
        gpsSensorId !== "" && (
          <GpsSensorSelection
            data={gpsData.gpsSensors}
            selectedId={gpsSensorId}
            selectSensor={(value: string) => setGpsSensorId(value)}
          />
        )}
      {gpsSensorId != null && (
        <Card className="mt-4">
          <GpsMap sensorId={gpsSensorId} />
        </Card>
      )}
      {gpsSensorId != null && <GpsHistoryTablePage sensorId={gpsSensorId} />}
    </div>
  )
}

export default GpsOverviewPage
